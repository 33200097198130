import React from "react";
import ShowMoreList from "../../common/ShowMoreList/ShowMoreList";
import { ProjectListItemProps } from "../../../types/ProjectListItem";
import { AccessLevel } from "../../../types/accessLevel";
import Input from "../../common/Input/Input";
import AccessLevelSelect from "../../common/AccessLevelSelect/AccessLevelSelect";
import ProjectListItem from "./ProjectListItem/ProjectListItem"
import "./ShowMoreProjectsList.scss"

interface ShowMoreProjectsListProps {
  projectList: any;
  clearSelectedItems: () => void;
  handleCheckboxToggle: (id: string) => void;
  workOrderSelectChangeHandler: (id: string, accessLevel: AccessLevel) => void;
  selectAllSelected: boolean;
  handleSelectAll: () => void;
  selectAllValue: AccessLevel;
  handleSetAllRoles: (accessLevel: AccessLevel) => void;
}

const ShowMoreProjectsList = (props: ShowMoreProjectsListProps) => {

  const listControls = (
    <div className="show-more-projects-list__list-controls">
      <Input
        type="checkbox"
        checked={props.selectAllSelected}
        onClick={props.handleSelectAll}
        label="Select all"
        labelRight
        className="show-more-projects-list__list-controls__input"
      />

      <AccessLevelSelect
        accessLevel={props.selectAllValue}
        className="show-more-projects-list__list-controls__select"
        selectHandler={props.handleSetAllRoles}
        label="Project Access"
      />
    </div>
  );

  return (
    <ShowMoreList<ProjectListItemProps>
      IDPropName="premiseID"
      includeSearch
      searchPlaceholder="Search by Address"
      data={props.projectList}
      incrementAmount={10}
      initialNumberDisplayed={5}
      searchableKeys={["projectAddress"]}
      emptyDataText="No projects available."
      onSearch={props.clearSelectedItems}
      controls={listControls}
    >
      {props.projectList.map((item: any) => {
        return (
          <ProjectListItem
            key={item.premiseID}
            selected={item.selected}
            premiseID={item.premiseID}
            projectAddress={item.projectAddress}
            electricNum={item.electricNum}
            gasNum={item.gasNum}
            userRole={item.userRole}
            checkboxHandler={props.handleCheckboxToggle}
            selectHandler={props.workOrderSelectChangeHandler}
          />
        );
      })}
    </ShowMoreList>
  )
};

export default ShowMoreProjectsList;