import React from "react";
import Card from "../../common/Card/Card";
import LogInContents from "../../common/LogInContents/LogInContents";

const LogInCard: React.FC<& {setGuestSearchWON:any; setStaticModalOpen?: any; }> = 
({ setGuestSearchWON, setStaticModalOpen }) => {

  const handleStaticModal = (val: boolean) => {
    setStaticModalOpen(val)
  }
  
  return (
    <Card className="log-in-card">
      <LogInContents setGuestSearchWON={setGuestSearchWON} handleStaticModal={handleStaticModal} />
    </Card>
  );
};

export default LogInCard;
