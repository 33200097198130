import React from "react";
import ErrorPage from "../ErrorPage/ErrorPage";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";

const WIPPage = (): React.ReactElement => {
  return (
    <ErrorPage>
      <h2>Coming Soon</h2>
      <p>This feature is not yet available. Please check back soon!</p>
      <ButtonPrimary to="/home">Home</ButtonPrimary>
    </ErrorPage>
  );
};

export default WIPPage;
