import React from 'react';
import Task from './Task';
import { standardizeStatus, formatDate } from '../../../utilities/utilities';
let moment = require('moment-business-days');

moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5]
});

const DocumentTask: React.FC<{
        label?   : string;
        status?  : string;
        type     : "project_documents" | "signed_easement";
        children?: any;
        date     : any;
        service  : "gas" | "electric";
    }> = ({
        label,
        status,
        type,
        children,
        date,
        service
    }): any => {{
    const currentDate     = moment();
    // date = "2019-12-12T14:39:50-05:00";
    const propDate        = moment(date);
    const dueDate         = service === "electric" ? propDate.businessAdd(15) : propDate;
    const businessDayDiff = currentDate.businessDiff(dueDate, 'days');
    const dayDiff         = Math.abs(dueDate.diff(currentDate, 'days') + 1);
    const isOverdue       = moment().isAfter(dueDate) ? true : false;
    const dueSoonRange    = {
        gas     : 5,
        electric: 10,
    };

    // const FORMAT_TESTING = 'ddd. MMM Do, YYYY, h:mm:ss a';
    const FORMAT_USER    = 'ddd. MMM Do, YYYY';

    const calcuateDataStatus = () => {
        if(date){
            // if dueDate is today
            if(businessDayDiff === 0){
                return "due_today";
            }
            // if dueDate is X or less business days away
            if(businessDayDiff <= dueSoonRange[service] && !isOverdue){
                // console.log(`due_soon - (${dayDiff}) regular, (${businessDayDiff}) business days away`);
                return "due_soon";
            }
            // if dueDate has passed by 1 or more days
            if(isOverdue){
                // console.log(`overdue - (${dayDiff}) regular, (${businessDayDiff}) business days overdue`);
                return "overdue";
            } else {
                return "pending_approval";
            }
        } else {
            return "pending_approval";
        }
    }

    const getStatus = () => {
        switch(status && standardizeStatus(status)){
            case "completed":
                return "approved";
            case "in_progress":
                if(date && type === "signed_easement" && service === "gas") {
                    return calcuateDataStatus();
                } else if(date && type === "project_documents"  && service === "electric") {
                    return calcuateDataStatus();
                } else {
                    return "pending_approval";
                }
            case "not_started":
                if(date && type === "signed_easement" && service === "electric" && moment().isAfter(propDate)) {
                    return calcuateDataStatus();
                } else if(date && type === "project_documents" && service === "gas" ){
                    return calcuateDataStatus();
                }
               return "not_started"
            case "to_do":
               return "not_started"
            case "not_required":
                return "not_required";
            case "on_hold":
                return "on_hold";
            default:
                return "not_started";
        };
    };

    const statusKey = getStatus();

    const icons = {
        future: {
            name:"future",
            altText:"Future"
        },
        pending: {
            name: "pending",
            altText: "Pending Approval"
        },
        completed: {
            name: "completed",
            altText: "Completed"
        },
        alert: {
            name: "alert",
            altText: "Due Soon/Upcoming"
        },
        emergency: {
            name: "emergency",
            altText: "Overdue"
        }
    }
    const label = `${type === "project_documents" ? "Project Documents" : (service === 'electric' ? "Signed Easement" : "Easement")}`;
    const statuses: any = {
            not_started: {
                color: "grey",
                icon: icons.future,
                project_documents: `Your ${label.toLowerCase()} have not been submitted.`,
                signed_easement: `Your ${label.toLowerCase()} has not been signed or submitted.`
            },
            due_soon: {
                color: "yellow",
                icon: icons.alert,
                project_documents:`Your ${label.toLowerCase()} are due in ${dayDiff} day${dayDiff > 1 ? `s`: ``}. Please submit the required documents to avoid project delays.`,
                signed_easement: `Your ${label.toLowerCase()} is due in ${dayDiff} day${dayDiff > 1 ? `s`: ``}. Please submit it to avoid project delays.`
            },
            due_today: {
                color: "yellow",
                icon: icons.alert,
                project_documents: `Your ${label.toLowerCase()} are due today. Please submit the required documents to avoid project delays.`,
                signed_easement: `Your ${label.toLowerCase()} is due today. Please submit it to avoid project delays.`
            },
            overdue: {
                color: "red",
                icon: icons.emergency,
                project_documents: `Your ${label.toLowerCase()} were due on ${formatDate(dueDate)}. Please submit the required documents to avoid further project delays.`,
                signed_easement: `Your ${label.toLowerCase()} was due on ${formatDate(dueDate)}. Please submit it to avoid project delays.`
            },
            pending_approval: {
                color: "yellow",
                icon: icons.pending,
                project_documents: `Your ${label.toLowerCase()} will be reviewed and approved by your ${service === "electric" ? "planner":"DTE representative"}.`,
                signed_easement: `Your ${label.toLowerCase()} will be reviewed and approved by your ${service === "electric" ? "planner":"DTE representative"}.`
            },
            not_approved: {
                color: "red",
                icon: icons.emergency,
                project_documents: `Your ${label.toLowerCase()} were not approved. Please resubmit the required document(s) once all issues have been resolved.`,
                signed_easement: `Your ${label.toLowerCase()} was not approved. Please resubmit it once all issues have been resolved.`
            },
            approved: {
                color: "green",
                icon: icons.completed,
                project_documents: `Your ${label.toLowerCase()} have been approved.`,
                signed_easement: `Your ${label.toLowerCase()} has been approved.`
            },
            not_required: {
                color: "green",
                icon: icons.completed,
                project_documents:`We do not need any documents for this project.`,
                signed_easement: `An easement is not required for your project.`
            },
            on_hold: {
                color: "red",
                icon: icons.emergency,
                project_documents: `Your ${label} has been placed on hold.`  ,
                signed_easement: `Your ${label} has been placed on hold.`
            }
    }

    return(
        <Task
            className   = "document-task"
            label       = {label}
            status      = {statusKey}
            statusColor = {statuses[statusKey].color}
            icon        = {statuses[statusKey].icon.name}
            iconAltText = {statuses[statusKey].icon.altText || null}
            message     = {statuses[statusKey][type] || null}
        >
            {children}
        </Task>
    );

}};

export default DocumentTask;