import React from "react";
import Project from "../../../types/project";
import Pill from "../../common/Pill/Pill";
import Tooltip from "../../common/Tooltip/Tooltip";
import "./ProjectHeader.scss";
import { formatAddress } from "../../../utilities/utilities"

const formatAccessLevel = (userRole: string) => {
  switch (userRole.toLowerCase()) {
    case "":
      return "View";
    case "view":
      return "View";
    case "edit":
      return "Edit";
    case "admin":
      return "Administrator";
    case "invite":
      return "Invite";
    case "owner":
      return "Owner";
    default:
      throw new Error(
        `ProjectHeader.ts: unexpected userRole type "${userRole}"`
      );
  }
};

const accessLevelToolTip = (userRole: string) => {
  switch (userRole.toLowerCase()) {
    case "":
    case "view":
      return "User can view contacts.";
    case "edit":
      return "User can view and edit contacts.";
    case "invite":
      return "User can view, edit and invite contacts.";
    case "admin":
      return "User can add, edit and manage all contacts.";
    case "owner":
      return "User can add, edit and manage all contacts.";
  }
};

const ProjectHeader = (props: Project) => {
  return (
    <header className="project-header">
      <div className="project-label-wrapper">
        <div className="project-address">
          {formatAddress(props.projectAddress)}
        </div>
        <div className="project-name">
          {/*{props.projectName}*/}
        </div>
      </div>
      <div className="project-access">
        <div className="block-label">Access</div>
        <div className="block-value pill-wrapper">
        <Tooltip tooltipBody={accessLevelToolTip(props.userRole)}><Pill>{formatAccessLevel(props.userRole)}</Pill></Tooltip>
        </div>
      </div>
      {/* <div className="project-completion-date table-date-col">
        <div className="block-label">Project Completion Date</div>
        <div className="block-value">
        </div>
      </div> */}
    </header>
  );
};

export default ProjectHeader;
