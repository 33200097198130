import React, { useState } from "react";
import Project from "../../../../../types/project";
import { formatAddress } from "../../../../../utilities/utilities";
import Tooltip from "../../../../common/Tooltip/Tooltip";
import "./ManageContactsProjectsListItem.scss";

interface ManageContactsProjectsListItemProps {
  changeHandler: (id: string, accessLevel: any) => void;
  removeFromProject: (id: string, event: React.ChangeEvent<HTMLButtonElement>) => void;
  removeMarkFromProject: (id: string) => void;
}

const ManageContactsProjectsListItem = (
  props: Project & ManageContactsProjectsListItemProps
) => {
  const [markedForRemoval, setMarkedForRemoval] = useState(false)

  let activeRole = props.userRole;

  if (props.userRole === "Admin" || props.userRole === "Owner") {
    activeRole = "Invite";
  }

  const roleChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newAccessLevelValue = event.target.value;
    if (props.premiseID) {
      props.changeHandler(props.premiseID, newAccessLevelValue);
    }
  };

  const cancelMarkedForRemoval = (event: React.ChangeEvent<HTMLButtonElement>) => {
    if(event.target.parentElement) {
      event.target.parentElement.classList.remove("remove");
    }
  };

  let btnContents = null;
  if (
    props.loggedInUserRole !== "View" &&
    props.loggedInUserRole !== "Edit"
  ) {
    btnContents = (
      <button
        type="button"
        className="manage-contacts-list-item__projects-list__item__remove-button"
        onClick={(e: any) => {
          props.removeFromProject(props.premiseID || props.premiseId || "just kidding", e)
          setMarkedForRemoval(true);
        }}
      >
        Remove
      </button>
    )
  } else {
    btnContents = (
      <span
        style={{ color: "#aaa" }}
        className="manage-contacts-list-item__projects-list__item__remove-button"
      >
        <Tooltip tooltipBody="Your access level prevents modification of this project">
          Remove
          </Tooltip>
      </span>
    );
  };


  return (
    <div
      key={props.premiseID}
      className={`manage-contacts-list-item__projects-list__item ${props.className || ""}`}
    >
      <div className="manage-contacts-list-item__projects-list__item__address">
        {formatAddress(props.projectAddress)}
      </div>
      
      {(props.loggedInUserRole === "View" || props.loggedInUserRole === "Edit") ?
        (
          <div className="manage-contacts-list-item__projects-list__item__select">
            <Tooltip tooltipBody="Your access level prevents modification of this project">
              <div className="select-wrapper">
                <select
                  value={activeRole}
                  id={props.premiseID}
                  data-role={props.userRole}
                  disabled
                >
                  <option value="View">View Only</option>
                  <option value="Edit">View &amp; Edit</option>
                  <option value="Invite">View, Edit &amp; Invite</option>
                </select>
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="manage-contacts-list-item__projects-list__item__select">
            <div className="select-wrapper">
              <select
                value={activeRole}
                onChange={(e) => roleChangeHandler(e)}
                id={props.premiseID}
                data-role={props.userRole}
              >
                <option value="View">View Only</option>
                <option value="Edit">View &amp; Edit</option>
                <option value="Invite">View, Edit &amp; Invite</option>
              </select>
            </div>
          </div>
        )}

      { !markedForRemoval ?
        btnContents :
          <button
          type="button"
          name="remove-button"
          className="manage-contacts-list-item__projects-list__item__cancel-button"
          onClick={(e: any) => {
            cancelMarkedForRemoval(e)
            props.removeMarkFromProject(props.premiseID || props.premiseId || "just kidding")
            setMarkedForRemoval(false);
          }}
          >Cancel</button>
      }
    </div>
  );
};

export default ManageContactsProjectsListItem;
