import React from "react";
import Task from "./Task";
import NumberFormat from 'react-number-format';

const InstallationTask: React.FC<{
  status: string;
  name?  : string;
  email? : string;
  phone? : string;
  service: "gas" | "electric";
}> = ({ status, service, name, email, phone }): any => {

    status = status.replace(/ /g, '_').toLowerCase()

    const statuses: any = {
      not_started: {
        color: "grey",
        icon: "future",
        altText: "Future",
        message: {
          gas: "",
          electric: ""
        },
        body: {
          gas: "Your natural gas service is not active.",
          electric: "Your electric connection is still in progress."
        }
      },
      in_progress: {
        color: "default",
        icon: "in_progress",
        altText: "To-Do",
        message: {
          gas: "",
          electric: ""
        },
        body: {
          gas: "Your natural gas service is not active.",
          electric: "Your electric connection is still in progress."
        }
      },
      completed: {
        color: "green",
        icon: "completed",
        altText: "Completed",
        message: {
          gas: "Your installation has been completed and natural gas service is active.",
          electric: "Your electric connection has been completed."
        },
        body: {
          gas: "Your natural gas service is active. If your site is not receiving natural gas, please call your DTE representative.",
          electric: "Your meter has been installed. If your site is not energized, please call your DTE Case Manager."
        }
      }
    }

    const getContactBlockIfNecessary = () => {
      if (status === 'completed' && (name || phone || email)) {
        return (
          <address className="contact-block">
          <div className="block-line">
              <div className="block-value">
                  {name}
              </div>
          </div>
          <div className="block-line">
              <div className="block-value">
                {service === 'gas' ? "DTE representative" : "DTE Case Manager" }
              </div>
          </div>
          {phone && (
              <div className="block-line">
                  <div className="block-value">
                      <NumberFormat
                          name        = "phoneValue"
                          type        = "tel"
                          format      = "(###) ###-####"
                          placeholder = "(   )   -"
                          value       = {phone}
                          displayType = "text"
                      />
                  </div>
              </div>
          )}
          {email && (
              <div className="block-line">
                  <div className="block-value">
                    <a href={`mailto:${email.toLowerCase() || " "}`}>{email.toLowerCase() || " "}</a>
                  </div>
              </div>
          )}
          </address>          
        )
      } else {
        return null
      }
    }

    return (
      <Task
        label={service === "gas" ? "Natural Gas Service is Active" : "Electric Connection"}
        statusColor={statuses[status].color}
        icon={statuses[status].icon}
        iconAltText={statuses[status].altText || null}
        message={statuses[status].message[service]}
      >
        {statuses[status].body[service]}
        <br />
        {getContactBlockIfNecessary()}
      </Task>
    );
};

export default InstallationTask;
