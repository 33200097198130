import React, { useEffect, useContext, createContext, useCallback, useReducer } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserSessionContextProvider } from "../../contexts/userSessionContext";
import LandingPage from "../LandingPage/LandingPage";
import ESILandingPage from "../ESI/ESILandingPage"
import NotFoundCard from "../ErrorPage/NotFoundCard/NotFoundCard";
import ContactUsPage from "../ContactUsPage/ContactUsPage";
import HelpResourcePage from "../HelpResourcesPage/HelpResourcePage";
import WorkOrderPage from "../WorkOrderPage/WorkOrderPage";
import AccountSettingsPage from "../AccountSettingsPage/AccountSettingsPage";
import ManageContactsPage from "../ManageContactsPage/ManageContactsPage";
import DashboardPage from "../DashboardPage/DashboardPage";
import { StaticModal } from "../common/StaticModal/StaticModal"
import WIPPage from "../WIPPage/WIPPage";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import ScrollToTopWhenLocationChanges from "../ScrollToTopWhenLocationChanges/ScrollToTopWhenLocationChanges";
import PrivateRoute from "../higherOrderComponents/PrivateRoute";
import GuestWorkOrderPage from "../WorkOrderPage/GuestWorkOrderPage";
import MockWorkOrderPage from "../WorkOrderPage/MockWorkOrderPage";
import NewServiceRequest from "../NewServiceRequest/NewServiceRequest";
import NewSRResults from "../NewServiceRequest/NewSRResults/NewSRResults";
import ErrorBoundary from "../common/ErrorBoundary/ErrorBoundary";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage";
import "../../commonStyles/commonStyles.scss";
import "./App.scss";
import ESIv2 from "../ESI/ESIv2/ESIv2";

const tabStylingApply = (event: KeyboardEvent) => {
  // finds elements that have .tab-trigger and removes it
  var elems = document.querySelectorAll(".tab-trigger");
  [].forEach.call(elems, function(el: Element) {
      el.classList.remove("tab-trigger");
  });

  // checking for tab check
  if (event.isComposing || event.keyCode === 9) {
    // console.log("hitting", document.activeElement);
    
    if(document.activeElement) {
      const elHTMLArray = document.getElementsByClassName("tab-to-trigger") || []
      let elArray = []

      // pushes HTMLcollection into an array
      for(const el of elHTMLArray) {
        elArray.push(el)
      }
      // finds the element that matches the active element
      const el = elArray.find( el => { 
        return el === document.activeElement 
      })
      el && el.classList.add("tab-trigger")
    }    
    return;
  }
}

window.addEventListener('keyup', tabStylingApply)

export const initialState = {
  currentHistory: "",
  previousHistory: ""
};

export type State = typeof initialState;

export type Action =
  | { type: 'setCurrentHistory', currentHistory: string }
  | { type: 'setPreviousHistory', previousHistory: string }

export const reducer = (state: State, action: Action) => {
  // console.log("Hitting the reducer!!!! state ->", state, "action ->", action )
  switch (action.type) {
      case 'setCurrentHistory':
      return {
          ...state,
          currentHistory: action.currentHistory,
      };
      case 'setPreviousHistory':
      return {
          ...state,
          previousHistory: action.previousHistory,
      };
      default: return state;
    }
  };

const ServiceRequestHistoryProvider = createContext(initialState);
const ServiceRequestHistoryDispatchContext = createContext((() => 0) as React.Dispatch<Action>);

const App = (): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCurrentHistory = useCallback((data) => dispatch({ type: 'setCurrentHistory', currentHistory: data }), [dispatch]);
  const setPreviousHistory = useCallback((data) => dispatch({ type: 'setPreviousHistory', previousHistory: data }), [dispatch]);

  const UserConfirmation = (message: any, callback: any) => {
    const container = document.createElement("div");
    container.setAttribute("custom-confirmation-navigation", "");
    document.body.appendChild(container);

    ReactDOM.unmountComponentAtNode(container);
    const closeModal = (callbackState: any) => {
      callback(callbackState);
    };
    ReactDOM.render(
      <StaticModal
      open={true}
      className={`open`}
      heading={"Before you go"}
      buttonText={"Leave"}
      text={message}
      onCancel={() => closeModal(false)}
      onConfirm={() => closeModal(true)}
    />,
    container
    );
  }

  useEffect(() => {
      setCurrentHistory(window.location.pathname)
  }, [state.previousHistory])


  return (
    <ServiceRequestHistoryDispatchContext.Provider value={dispatch} >
    <ServiceRequestHistoryProvider.Provider value={state} >
    <div className="App"
      onLoad={() => {
        if(window.location.pathname !== state.currentHistory) {
          setPreviousHistory(state.currentHistory)
        }
      }}
    >
      <a className="skip-main" href="#main">Skip to main content</a>
      <BrowserRouter getUserConfirmation={UserConfirmation}>
        <ScrollToTopWhenLocationChanges />
        <UserSessionContextProvider>
          <NavBar />
          <div id="main">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={process.env.REACT_APP_ENV === "azure" ? ESILandingPage : LandingPage} />
              <Route exact path="/contact-us" component={ContactUsPage} />
              <Route exact path='/guest-search/:num' component={GuestWorkOrderPage} />
              <Route exact path="/employees-v2" component={ESIv2} />
              <Route exact path="/help-resources" component={HelpResourcePage} />
              <Route exact path="/new-sr-results/:data?" component={NewSRResults} />
              <Route exact path="/mock-work-order" component={MockWorkOrderPage} />
              <Route exact path="/change-password" component={ForgotPasswordPage} />
              <Route exact path="/reset-password" component={ResetPasswordPage} />
              <PrivateRoute path="/work-order/:num?" component={WorkOrderPage} />
              <PrivateRoute path="/account-settings-preview" component={AccountSettingsPage} />
              <PrivateRoute path="/account-settings" component={WIPPage} />
              <PrivateRoute path="/projects" component={WIPPage} />
              <PrivateRoute path="/(home|dashboard)" component={DashboardPage} />
              <PrivateRoute path="/(contacts|manage-contacts)" component={ManageContactsPage} />
              <PrivateRoute path="/start-new-service" component={NewServiceRequest} />
              <PrivateRoute component={NotFoundCard} />
            </Switch>
          </ErrorBoundary>
          </div>
          <Footer />
        </UserSessionContextProvider>


      </BrowserRouter>

    </div>
    </ServiceRequestHistoryProvider.Provider>
    </ServiceRequestHistoryDispatchContext.Provider>
  );
};
export const useDispatch = () => {
  return useContext(ServiceRequestHistoryDispatchContext);
};

export const useGlobalState = <K extends keyof State>(property: K) => {
  const state = useContext(ServiceRequestHistoryProvider);
  return state[property]; // only one depth selector for comparison
};


export default App;
