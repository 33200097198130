import React, { useContext } from "react";
import ButtonPrimary from "../../../common/ButtonPrimary/ButtonPrimary";
import { ModalContext } from "../../../../contexts/modalContext"
import "./UpdateEmailModalContent.scss";


const UpdateEmailModalContent = () => {

  const { setModalOpen } = useContext(ModalContext);

  const data = {
    name: "woody",
    company: "pixar",
    position: "cowboy",
    phone: "9898241232",
    address: "1418 ARCHWOOD DR, OAKLAND, MI 48363-1705",
    email: "woody@pixar.com"
  };

  return (
    <div className="update-email-modal">
      <div className="update-email-modal__explanation-paragraph">
        <p>Changing your primary email address will change your user name.<br/>
        Are you sure you want to change your primary email address?</p>
    </div>
      <form className="update-email-modal__form">
        <span>Current Email (User ID):</span><span>{data.email}</span>
        <div>
          <label htmlFor="email">
            New Email Address*
          <input
              name="emailValue"
              type="email"
              placeholder="example@example.com"
              aria-label="new email"
            // value={data.email}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            // ref={emailInputRef}
            />
            {/* <div className="error email-error" data-testid="email-error">
            Please enter a valid email address. (ex: name@email.com)
          </div> */}
          </label>
        </div>
        <div>
          <label htmlFor="email">
            Confirm Email Address
          <input
              name="emailValue"
              type="email"
              placeholder="example@example.com"
              aria-label="confirm email"
            // value={data.email}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            // ref={emailInputRef}
            />
            {/* <div className="error email-error" data-testid="email-error">
            Please enter a valid email address. (ex: name@email.com)
          </div> */}
          </label>
        </div>

      </form>
      <ButtonPrimary
        className="update-email-modal__save-btn"
        onClick={() => { }}
      >
        Save
    </ButtonPrimary>
      <ButtonPrimary
        className="update-email-modal__cancel-btn"
        onClick={() => { setModalOpen(false) }}
      >
        Cancel
    </ButtonPrimary>
    </div>
  );
};

export default UpdateEmailModalContent;
