import React, { useState, useEffect } from "react";
import { cacheHandler } from "../../../../utilities/cacheUtils";
import { titleCase } from "../../../../utilities/utilities";
import checkMarkIcon from "../../../../assets/checkmark.svg";
import Input from "../../../common/Input/Input"
import "./ProjectNotifications.scss";


const dataRaw = require("./mockNotifProj.json");

interface Props {
  editing: boolean,
  isCanceled: boolean,
  isSaved: boolean
}

const ProjectNotifications: React.FunctionComponent<Props> = (
  props: Props
): React.ReactElement => {
  const checkMark = <img src={checkMarkIcon} alt="check mark" />;

  const [globalPreferences, setGlobalPreferences] = useState<any>({});
  // const [updatedPreferences, setUpdatedPreferences] = useState([]);

  const [selectedRole, setSelectedRole] = useState("admin");
  const contactTypes = ["Email", "Text", "Recent"];
  let rolePreferences: any = {};


  const getPreferences = async () => {
    dataRaw.notificationPreferences.forEach((d: any) => {
      let roleName = d.role.toLowerCase();
      rolePreferences[roleName] = d.notificationCategory[0].notificationTypes;
    });
    cacheHandler.set('AccountPreferences', JSON.stringify(rolePreferences))
    setGlobalPreferences(rolePreferences)


    // await axios.get(`${getDomain()}pls-get-project-notifications`).then(r => {
    //   // console.log(r.data)
    //   r.data.notificationPreferences.map((d: any) => {
    //     let roleName = d.role.toLowerCase();
    //     rolePreferences[roleName] = d.notificationCategory[0].notificationTypes;
    //   });
    //   cacheHandler.set('AccountPreferences', JSON.stringify(rolePreferences))
    //   setGlobalPreferences(rolePreferences)
    // }).catch(e => {
    //   console.error(e)
    // })
  }

  useEffect(() => {
    getPreferences()
  }, [])

  useEffect(() => {
    const originalPreferences = JSON.parse(cacheHandler.get('AccountPreferences') || "[]")
    setGlobalPreferences(originalPreferences)
  }, [props.isCanceled])

  useEffect(() => {
    cacheHandler.set('AccountPreferences', JSON.stringify(globalPreferences))
  }, [props.isSaved])

  const description: { [key: string]: any } = {
    contacts: {
      title: "Project Contacts",
      settings: {
        project_contacts:
          "Project Contacts - Notification when a project contact is added, removed, or leaves a project that you have invitation access to."
      }
    },
    status: {
      title: "Project Status",
      settings: {
        account_notifications:
          "Project Status - Status update when your projects move from one phase to the next.",
        project_canceled:
          "Project Canceled - Notification when your work order has been canceled."
      }
    },
    dte_tasks: {
      title: "DTE Tasks",
      settings: {
        dte_assignment:
          "Assignment - Status update when your project has been assigned to a new DTE representative.",
        dte_delayed:
          "Task Delayed -  Notification when a DTE task has been placed on hold because of a delay."
      }
    },
    user_tasks: {
      title: "Your Tasks",
      settings: {
        task_new:
          "New Task - Notification that you have a new task ready to be completed.",
        task_approved:
          "Task Approved - Confirmation that your submitted task has been approved.",
        task_not_approved:
          "Task Not Approved - Notification that your completed tasks were marked as not approved by your DTE representative.",
        task_due_soon:
          "Task Due Soon - Reminder 5 days before any incomplete tasks are due.",
        task_overdue:
          "Overdue Task - Reminder that you have incomplete tasks that are past due."
      }
    },
    payment: {
      title: "Payment",
      settings: {
        payment_past_due:
          "Payment Past Due - Notification when your payment is past due.",
        payment_submitted:
          "Payment Submitted - Notification when a payment has been submitted.",
        payment_posted:
          "Payment Posted - Notification when your payment has been posted."
      }
    },
    reminders: {
      title: "Reminder Frequency",
      settings: {
        reminders:
          "Manage how often you would like to receive friendly follow up reminders that you have incomplete tasks that are past due."
      },
      options: {
        once:
          "Remind Me Once - One reminder 2 days after the task becomes past due.",
        weekly:
          "Weekly Reminders - Remind me weekly until the task is completed."
      }
    }
  };

  const contactMethodSelected = (contactMethods: any, contactType: string) => {
    return contactMethods &&
      contactMethods.find((mt: any) => mt.mediumType === contactType)
      ? true
      : false;
  };

  const changeHandler = (
    methodExists: boolean,
    contactMethods: [object | string],
    contactType: string
  ) => {
    if (methodExists) {
      const methodIndex = contactMethods.findIndex(
        (m: any) => m.mediumType === contactType
      );
      methodIndex > -1 && contactMethods.splice(methodIndex, 1);
    } else {
      contactMethods.push({ mediumType: contactType });
    }
    // setUpdatedPreferences(rolePreferences);
  };

  const renderContactTypes = (settingsGroup: string) => {
    return settingsGroup !== "reminders"
      ? contactTypes
      : contactTypes.slice(0, 2);
  };

  const animateTransition = () => {
    var projects = document.getElementById("project-settings-body")
    projects && projects.classList.add("fade");
    setTimeout(() => {
      projects && projects.classList.remove("fade");
    }, 100)
  }

  return (
    <>
      <h4>Project Notifications</h4>
      <span>
        Manage how you would like to be notified when specific project events
        occur based on access level.
      </span>
      <br />
      <br />

      <div className="project-settings__roles">
        {Object.keys(globalPreferences).map(role => {
          return (
            <div className={selectedRole === role ? "selected" : ""}>
              <button
                className="link-button"
                onClick={() => {
                  animateTransition()
                  setSelectedRole(role);
                }}
              >
                {titleCase(role)}
              </button>
            </div>
          );
        })}
      </div>
      <div id="project-settings-body">
        {Object.keys(description).map((settingsGroup: any) => {
          {
            return (
              <div
                className={`project-settings ${settingsGroup === "reminders" ?
                  "reminders" : ""}`}
              >
                {settingsGroup === "reminders" && (
                  <>
                    <p>Overdue Task Reminders</p>
                    <p>
                      Manage how often you would like to receive friendly follow
                      up reminders that you have incomplete tasks that are past
                      due.
                  </p>
                  </>
                )}
                <div className="project-settings__heading">
                  <h5>{description[settingsGroup].title}</h5>
                </div>
                <div className="project-settings__type">
                  {renderContactTypes(settingsGroup).map(contactType => {
                    return (
                      <div>
                        <h5>{contactType}</h5>
                      </div>
                    );
                  })}
                </div>

                {Object.keys(globalPreferences).length > 0 && Object.keys(description[settingsGroup].settings).map(
                  (setting: string) => {
                    const contactMethods = globalPreferences[selectedRole].find((pref: any) => pref.name === setting).contactMethods;
                    return (
                      <>
                        <div className="project-settings__heading">

                          {settingsGroup !== "reminders" ? <p>{description[settingsGroup].settings[setting]}</p> :
                            (
                              !props.editing ? (<p>{description[settingsGroup].settings[setting]}</p>) : (
                                <div className="select-wrapper">
                                  <select className="project-settings__select">
                                    {Object.keys(description[settingsGroup].options).map(option => {
                                      return <option value="" >{description[settingsGroup].options[option]}</option>
                                    })}
                                  </select>
                                </div>
                              )
                            )
                          }

                        </div>
                        <div className="project-settings__type">
                          {renderContactTypes(settingsGroup).map(contactType => {
                            const methodSelected = contactMethodSelected(contactMethods, contactType);
                            return (
                              <div className="input">
                                {!props.editing ? (
                                  <p>{methodSelected ? checkMark : null}</p>
                                ) : (settingsGroup !== "reminders" ? (

                                  <Input
                                    type="checkbox"
                                    onClick={() => {
                                      changeHandler(
                                        methodSelected,
                                        contactMethods,
                                        contactType
                                      )
                                    }}
                                    checked={methodSelected}
                                  />

                                ) : (
                                    <Input
                                      type="radio"
                                      onClick={() => {
                                        changeHandler(
                                          methodSelected,
                                          contactMethods,
                                          contactType
                                        )
                                      }}
                                      checked={methodSelected}
                                    />
                                  )

                                  )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default ProjectNotifications;
