import axios from "axios"
import getDomain from "./getDomain"
import { getInProgressStep } from "../utilities/utilities";
import { cacheHandler } from "../utilities/cacheUtils";

const getLatestLastUpdated = (project: any) => {
  if (!project.workOrders.length) { return "1" }
  return Math.max.apply(Math, project.workOrders.map((workOrder: any) => {
      return Math.max.apply(Math,
        workOrder.steps.map((step: { last_updated: string }) => new Date(step.last_updated || "1/1/2019").getTime())
      );
    })
  ).toString();
};

const getProjectLatestInProgress = (project: any) => {
  if (!project.workOrders.length) { return "0" }
  return Math.max.apply(Math, project.workOrders.map((workOrder: any) => {
    return workOrder.latestStepInProgress
  })).toString()
}

const getWorkOrderNumbersString = (project: any) => {
  let WONArray = project.workOrders.map((workOrder: any) => {
    return workOrder.PWO
  })
  return WONArray.join(" ")
}

export const getActiveBPID = async (uid: string = "") => {
  let currentDate = new Date();
  let currentTimestamp = currentDate.getTime();
  let firstBP = await axios
    .get(
      `${getDomain()}api/construction/usersManagement/v1/users/businessPartners?timestamp=${currentTimestamp}`,
      { withCredentials: true }
    )
    .then(r => {
      let firstBP = "";
      if (!r.data.businesses) {
        firstBP = uid || "";
      } else {
        if (cacheHandler.get("ActiveBPID")) {
          firstBP =
            cacheHandler.get("ActiveBPID") || r.data.businesses[0].id;
        } else {
          firstBP = r.data.businesses[0].id;
        }
        if (r.data.businesses.length > 1) {
          let activeData = r.data.businesses.filter((biz: any) => {
            return biz.id === Number(firstBP);
          });
          cacheHandler.set(
            "activeBPName",
            activeData[0] ? activeData[0].name : ""
          );
          cacheHandler.set("allBPs", JSON.stringify(r.data.businesses));
        }
      }
      return firstBP;
    })
    .catch(e => {
      return uid;
    })
    return firstBP
}

const getInitialData = async (activeBPID: string) => {
  return axios
  .get(`${getDomain()}api/construction/usersManagement/v1/users/accountSummary/businessId/${activeBPID}`, { withCredentials: true })
  .then(r => {
    localStorage.setItem("cmcElevatedRole", r.data.elevatedRole || "View");
    // if (newStructure) {
    //   r.data = require('./structure3.json')
    // };
    // console.log(r.data)
    return r.data;
  })
  .catch(e => {
    throw Error("Sorry, we couldn't find any valid data for this account")
  });
}

let getValidWorkOrderData = async (workOrders: any) => {
  let newWons: any[] = [];
  await Promise.all(
    workOrders.map((workOrder: any) => {
      return axios
        .get(`${getDomain()}api/construction/workOrderManagement/v1/workorderStatus?WONum=` + workOrder.num, { withCredentials: true })
        .then(r => {
          if (r.data) {
            r.data.latestStepInProgress = "0";
            r.data.steps.length > 1 && (r.data.latestStepInProgress = getInProgressStep(r.data.steps).toString());
            newWons.push(r.data);
          }
        })
        .catch(e => []);
    })
  );
  return newWons;
};

let getWODataBySOs = async (initialData: any) => {
  let projectsWithSONumsArray = initialData.projectsList.map((p: any) => {
    let serviceOrderNumbers = p.serviceOrders.map((s: any) => {
      return s.projectID;
    });
    let summaryData = p.serviceOrders.map((s: any) => {
      // console.log('s')
      // console.log(s)
      return {
        projectID: s.projectID,
        jobType: s.jobType,
        developmentType: s.developmentType,
        dateReady: s.dateReady || "",
        projectStatus: s.projectStatus || "",
        serviceNeeded: s.serviceNeeded,
        meterInstallation: s.meterInstallation,
        installationNumber: s.installationNumber,
        installationEligible: s.installationEligible,
        serviceType: s.serviceType,
        projectInitiator: s.projectInitiator,
        contacts: initialData.contacts,
        gateCode: s.gateCode || ""
      };
    });
    cacheHandler.set('summaryData', JSON.stringify(summaryData))
    p.serviceOrderNumbers = serviceOrderNumbers;
    p.summaryData = summaryData;
    return p;
  });
  let ps: any[] = [];
  let SOmap: any[] = [];
  let WOmap: any[] = [];
  await Promise.all(
    projectsWithSONumsArray.map(async (project: any) => {
      let newProject = project;
      newProject.workOrders = []
      await Promise.all(project.serviceOrderNumbers.map(async (so: any) => {
        await axios.get(
          `${getDomain()}api/construction/workOrderManagement/v1/workOrders?serviceOrderNum=${so}`,
          { withCredentials: true}
          ).then(async r => {
            SOmap.push({ num: so, workOrders: r.data.workOrders})
            r.data.workOrders.forEach((wo: any) => {
              WOmap.push({ won: wo.num, so: so, premiseID: project.premiseID});
            })
            let validWorkOrders = await getValidWorkOrderData(r.data.workOrders)
            newProject.workOrders = [...newProject.workOrders, ...validWorkOrders];
        }).catch(e => { })
      }))
      newProject.workOrdersString = getWorkOrderNumbersString(newProject);
      newProject.latestInProgress = getProjectLatestInProgress(newProject);
      newProject.last_updated = newProject.last_updated ?
        newProject.last_updated : getLatestLastUpdated(newProject);
      ps.push(newProject);
    })
  ) // end of promise all
  cacheHandler.set('SOmap', JSON.stringify(SOmap));
  cacheHandler.set('WOmap', JSON.stringify(WOmap));

  return ps
}

const getWODataBySO = async (initialData: any) => {
  let sanitizedProjects = initialData.projectsList.map((p: any) => {
    p.workOrders = [];
    return p;
  });
  let ps: any[] = [];
  await Promise.all(
    sanitizedProjects.map(async (project: any) => {
      return axios
        .get(
          `${getDomain()}api/construction/workOrderManagement/v1/workOrders?serviceOrderNum=${
            project.projectID
          }`,
          { withCredentials: true }
        )
        .then(async r => {
          let newProject = project;
          newProject.workOrders = r.data.workOrders;
          let validWorkOrders = await getValidWorkOrderData(newProject.workOrders);
          newProject.workOrders = validWorkOrders;
          ps.push(newProject);
        })
        .catch(e => {
          let newProject = project;
          newProject.workOrders = [];
          ps.push(newProject);
        });
    })
  );
  return ps;
}

export const getAccountData = async (activeBPID: string) => {

  let ID = activeBPID || cacheHandler.get('BusinessId') || "";
  let initialData = await getInitialData(ID);
  let goodProjectsList: any[] = [];

  goodProjectsList =
    initialData.projectsList[0] && initialData.projectsList[0].serviceOrders ?
    await getWODataBySOs(initialData) : await getWODataBySO(initialData);
  let finalData = initialData;
  goodProjectsList.sort((a, b) => a.projectAddress > b.projectAddress ? 1 : -1);
  finalData.projectsList = goodProjectsList;
  cacheHandler.set('ProjectsList', JSON.stringify(finalData.projectsList));
  cacheHandler.set('AccountData', JSON.stringify(finalData))
  return finalData;

}