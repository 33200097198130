import React from "react";
import ClickToOpenModal from "../ClickToOpenModal/ClickToOpenModal";
import { ModalContextProvider } from "../../../contexts/modalContext";

interface LinkToModalProps {
  title: string;
  linkText: string;
}

const LinkToModal: React.FunctionComponent<
  React.PropsWithChildren<LinkToModalProps>
> = (props: React.PropsWithChildren<LinkToModalProps>): React.ReactElement => {
  return (
    <ModalContextProvider>
      <ClickToOpenModal title={props.title} type="link" text={props.linkText}>
        {props.children}
      </ClickToOpenModal>
    </ModalContextProvider>
  );
};

export default LinkToModal;
