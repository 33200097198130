import React from "react";
import MediaQuery from "react-responsive";
import breakpoints from "../../../utilities/breakpoints";
import Card from "../Card/Card";
import "./HowItWorksCard.scss";
import howItWorksImg1x from "../../../assets/img/cmc-how-it-works-1x.png";
import howItWorksImg2x from "../../../assets/img/cmc-how-it-works-2x.png";
import howItWorksImg3x from "../../../assets/img/cmc-how-it-works-3x.png";

const HowItWorksCard = (): React.ReactElement => {
  const img = (
    <img
      className="how-it-works__img-container__img"
      srcSet={`${howItWorksImg1x} 436w, ${howItWorksImg2x} 872w, ${howItWorksImg3x} 1308w`}
      src={howItWorksImg3x}
      alt="Construction workers"
    />
  );

  return (
    <>
      <Card className="how-it-works">
        <div className="how-it-works__content">
          <h2>How It Works</h2>
          <p>
            DTE Energy makes it simple for contractors to plan and install
            electric or gas service on construction projects.
          </p>
          <ul>
            <li>
              Sign in to request new service or get status updates
              on existing projects.
            </li>
            <li>
              Track your projects’ progress with real-time updates and
              notifications.
            </li>
            <li>
              Access project details and update your progress by completing
              installation requirements and submitting documents.
            </li>
            <li>
              Invite others to view and manage your projects with the ability to
              determine their level of access.
            </li>
            <li>Filter and search all of your active projects.</li>
            <li>Copy and create similar work orders to save time.</li>
          </ul>
        </div>
        <MediaQuery minDeviceWidth={breakpoints.desktopMinWidth}>
          <div className="how-it-works__img-container">{img}</div>
        </MediaQuery>
      </Card>
      <MediaQuery maxDeviceWidth={breakpoints.tabletMaxWidth}>
        <Card className="how-it-works__img-container">{img}</Card>
      </MediaQuery>
    </>
  );
};

export default HowItWorksCard;
