import React, { useContext } from "react";
import ButtonPrimary from "../../../common/ButtonPrimary/ButtonPrimary";
import { ModalContext } from "../../../../contexts/modalContext"
import "./UpdatePasswordModalContent.scss";


const UpdatePasswordModalContent = () => {
  const { setModalOpen } = useContext(ModalContext);


  return (
    <div className="update-password-modal">
      <div className="update-password-modal__explanation-paragraph">
        <p>Are you sure you want to change your password?</p>
    </div>
      <form className="update-password-modal__form">
        <div>
          <label htmlFor="password">
            Current Password*
          <input
              name="passwordValue"
              type="password"
              aria-label="current password"
            // value={data.password}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            // ref={passwordInputRef}
            />
            {/* <div className="error password-error" data-testid="password-error">
            Please enter a valid password address. (ex: name@password.com)
          </div> */}
          </label>
        </div>
        <div>
          <label htmlFor="password">
            New Password*
          <input
              name="passwordValue"
              type="password"
              aria-label="new password"
            // value={data.password}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            // ref={passwordInputRef}
            />
            {/* <div className="error password-error" data-testid="password-error">
            Please enter a valid password address. (ex: name@password.com)
          </div> */}
          </label>
          <label htmlFor="password">
            Confirm New Password*
          <input
              name="passwordValue"
              type="password"
              aria-label="confirm password"
            // value={data.password}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            // ref={passwordInputRef}
            />
            {/* <div className="error password-error" data-testid="password-error">
            Please enter a valid password address. (ex: name@password.com)
          </div> */}
          </label>
        </div>
        <p>Your password needs to be at least 8 characters and must include at least one uppercase letter and one number.</p>

      </form>
      <ButtonPrimary
        className="update-password-modal__save-btn"
        onClick={() => { }}
      >
        Save
    </ButtonPrimary>
      <ButtonPrimary
        className="update-password-modal__cancel-btn"
        onClick={() => { setModalOpen(false) }}
      >
        Cancel
    </ButtonPrimary>
    </div>
  );
};

export default UpdatePasswordModalContent;
