import { useState, useEffect } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import getDomain from "./getDomain";
import { cacheHandler } from "../utilities/cacheUtils";

export const genericErrorMessage = "Sorry, an error occurred.";
const prefix = getDomain() + "api";
export const paths = {
  signIn: `${prefix}/signIn`,
  getWorkOrder: `${prefix}/construction/workOrderManagement/v1/workorderStatus?WONum=`,
  getProjects: `${prefix}/construction/usersManagement/v1/users/accountSummary/businessId/`, //1100616888?webUserId=BP1100616888@DTEACCEPT.COM
  postNewServiceRequest: `${prefix}/construction/usersManagement/v1/users/serviceRequest/businessId/${cacheHandler.get("ActiveBPID")}`
};

export const useAPI = (
  path = "",
  method = "get",
  incomingData = {},
  incomingCount = 0
) => {
  const [error, setError] = useState<AxiosError | null | void>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [url, setUrl] = useState(path);
  const [count, setCount] = useState(incomingCount);
  const { CancelToken } = axios;

  // To trigger a call, the url must be not be an empty string (the default value) and count must be > 0.
  // If you don't trigger a call initially, use the following function to trigger as needed.
  // It will increment the count if you don't pass a new URL.
  const trigger = (newPath = "") =>
    newPath && newPath !== url && setErrorMessage("") ? setUrl(newPath): setCount(count + 1);

  useEffect(() => {
    if (!count || !url) return;
    (async () => {
      setLoading(true);
      const source = CancelToken.source();
      const config = {
        method,
        url,
        withCredentials: true,
        cancelToken: source.token
      } as AxiosRequestConfig;
      if (method === "post") { config.data = incomingData; }
      try {
        const res = await axios(config);
        setData(res.data);
        setErrorMessage("");
      } catch (e) {
        setError(e);
        // Errors may not come from client and may take different forms. E.g. CORS. Expand this.
        if (e.response && e.response.data && e.response.data.errorMessage) {
          setErrorMessage(e.response.data.errorMessage);
        } else if (e.message) {
          setErrorMessage("Network or CORS error.");
        } else {
          setErrorMessage(genericErrorMessage);
        }
        setData(null);
      }
      setLoading(false);
    })();
  }, [count, url]);

  return { errorMessage, loading, data, trigger, error };
};

export const handleErrorMessage = (e: any) => {
  if (e.response && e.response.data) {
     return e.response.data.errorMessage;
  } else if (e.message) {
     return "Network or CORS error.";
  } else {
    return genericErrorMessage;
  }
}