import React, { useState } from "react";
import Card from "../../common/Card/Card"
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import ProfileNotifications from "./ProfileNotifications/ProfileNotifications";
import ProjectNotifications from "./ProjectNotifications/ProjectNotifications";

import "./NotificationSettingsCard.scss"

const NotificationSettingsCard = (): React.ReactElement => {

  const [editing, setEditing] = useState<boolean>(false)
  const [isCanceled, setIsCanceled] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)

  const manageNotificaitons = (type: string | null) => {
    if (type === "save") {
      setEditing(false)
      setIsSaved(true)
    } else if (type === "cancel") {
      setEditing(false)
      setIsSaved(false)
      setIsCanceled(true)
    } else {
      setEditing(true)
      setIsSaved(false)
      setIsCanceled(false)
    }
  }

  return (
    <div className="notification-settings-card">
      <h3>Notification Settings</h3>
      <Card>
        <ProfileNotifications editing={editing} />
        <ProjectNotifications isCanceled={isCanceled} isSaved={isSaved} editing={editing} />

        {!editing ? (
          <ButtonPrimary
            onClick={() => { manageNotificaitons(null) }}
          >Manage Notifications
          </ButtonPrimary>
        ) : (
            <>
              <ButtonPrimary
                onClick={() => { manageNotificaitons("save") }}
              >Save
            </ButtonPrimary>
              <ButtonPrimary
                className="notification-settings-card__cancel-btn"
                onClick={() => { manageNotificaitons("cancel") }}
              >Cancel
            </ButtonPrimary>
            </>
          )}
      </Card>
    </div>
  );
};

export default NotificationSettingsCard;

//preserve original state
//revert to original state
//save edited state