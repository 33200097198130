import React from 'react';
import NumberFormat from 'react-number-format';
import Accordion from "../../common/Accordion/Acordion"
import ButtonToModal from '../ButtonToModal/ButtonToModal';
import ButtonToContactSupervisorModal from "./ContactSupervisorModal/ButtonToContactSupervisorModal"
import { WorkOrder } from "../../../types/workOrder";
import {getMostRecentContact} from "../../../utilities/utilities"
import "./QuestionOrConcernPopover.scss"

const QuestionOrConcernPopover: React.FC<{ data?: WorkOrder }> = ({
  data
}): React.ReactElement => {

  const contact = data && getMostRecentContact(data.steps, data.service_type_id)

  const body = (
    <div className="question-or-concern__body">
      <p>
        We are here to help you during each phase of your construction project.
      </p>

      {data && contact ? (
        <div className="question-or-concern__contact">
        <h4>Your DTE Energy representative:</h4>
        <div className="question-or-concern__contact-info">
          <p><strong>{contact.name}</strong></p>
          <p>{contact.title}</p>
          <p>
          <NumberFormat
            displayType = "text"
            format      = "(###) ###-####"
            value       = {contact.phone}
          />
          </p>
          <button className="link-button">{contact.email.toLowerCase()}</button>
        </div>
      </div>
      ):(
        <p>
          For questions regarding your project, DTE project contact information can be found in steps 2, 3, and 7 of you work order or you can contact your regional service center.
        </p>
      )}

      <p>
        <strong>
          Have an urgent or recurring situation that needs addressed
          immediately?
        </strong>
      </p>

      <ButtonToContactSupervisorModal address={data && data.address} workOrder={data && data.PWO}/>
    </div>
  );


  return (
    <div className="question-or-concern">
      {window.screen.width > 767 ? (
        <Accordion heading={<p>Have a question or concern?</p>} includeWhiteChevron>
          {body}
        </Accordion>
      ) : (
          <ButtonToModal title="Have a question or concern?" linkText="?">
            {body}
          </ButtonToModal>
        )}
    </div>
  );
}
export default QuestionOrConcernPopover;