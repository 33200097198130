import React, { useState, useEffect } from "react";
import Card from "../../common/Card/Card";
import Input from "../../common/Input/Input";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import chevronIcon from "../../../assets/chevron-down-brand-active.svg"
import "./ESIv2.scss"


const ESILogInCard: React.FC<any> = (props) => {
  const [WON, setWON] = useState("") //53955399

  //Employee Search
  const searchOptions = ["Site Details", "Customer Details", "DTE Respresentative"];
  const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0])
  const [advancedSearch, setAdvancedSearch] = useState(false)

  //Customer Details / DTE Assignment 
  const detailsOptions = ["Customer Details", "DTE Assignment"];
  const [selectedDetailsOption, setSelectedDetailsOption] = useState(detailsOptions[0])



  const handleWONInput = (e: any) => {
    let v = e.target.value
    v = v.replace(/[^\d]/g, '')
    if (v.length > 8) {
      v = v.substr(0, 8)
    }
    setWON(v)
  }

  return (
    <div className="narrow">
     
      {/* Employee Search */}
      <Card className="esi-search">
        <h3>{!advancedSearch ? "Search Work Orders" : "Advanced Search"}</h3>
        
        {!advancedSearch ?
        <div className="esi-search__basic-search">
          <div className="esi-search__basic-search__input-container">
            <input type="text" placeholder="Search" onChange={handleWONInput} value={WON} />
            <div className="select-wrapper" >
              <select>
                <option>Work Order Number</option>
              </select>
            </div>
            <ButtonPrimary href="#" disabled={WON.length < 8}>Search</ButtonPrimary>
          </div>
          <button className="link-button esi-search__basic-search__advanced-btn" onClick={() => setAdvancedSearch(true)}>
            Advanced Search
            <img
              className="accordion__header__chevron"
              src={chevronIcon}
              alt="chevron"
            />
          </button>
        </div>
        :
        <div className="esi-search__advanced-search">
          <div className="esi-search__advanced-search__options">
            {searchOptions.map(option => {
              return (
                <div className={selectedSearchOption === option ? "selected" : ""}>
                  <button
                    className="link-button"
                    onClick={() => {
                      setSelectedSearchOption(option)
                    }}
                  >
                    {option}
                  </button>
                </div>
              );
            })}
          </div>
          
          <div className={`esi-search__advanced-search__site-details ${selectedSearchOption === searchOptions[0] ? "show" : ""}`}>
            <div className="esi-search__advanced-search__site-details__job-type">
              <p>Job Type:</p>
              <Input type="checkbox" labelRight label="New Electric Service" />
              <Input type="checkbox" labelRight label="New Gas Service" />
              <Input type="checkbox" labelRight label="Electric Service Alteration" />
              <Input type="checkbox" labelRight label="Gas Service Alteration" />
            </div>
            <div className="esi-search__advanced-search__site-details__input-container">
              <label>
                <p>Street Address</p>
                <input type="text" placeholder="Street Name and/or Number" />
              </label>
              <label>
                <p>Development Address</p>
                <input type="text" placeholder="Subdivision or Development" />
              </label>
              <label>
                <p>City</p>
                <input type="text" placeholder="City, Township, or Village" />
              </label>
              <label>
                <p>Zip Code</p>
                <input type="text" placeholder="Zip Code" />
              </label>
            </div>
          </div>

          <div className={`esi-search__advanced-search__customer-details ${selectedSearchOption === searchOptions[1] ? "show" : ""}`}>
            <div className="esi-search__advanced-search__customer-details-input-container">
              <label>
                <p>Customer</p>
                <input type="text" placeholder="Customer Name or Company" />
              </label>
              <label>
                <p>Email Address</p>
                <input type="text" placeholder="example@example.com" />
              </label>
              <label>
                <p>Customer BP Number</p>
                <input type="text" placeholder="10-Digit BP Number" />
              </label>
              <label>
                <p>Meter Number</p>
                <input type="text" placeholder="?-Digit Meter Number" />
              </label>
            </div>
          </div>

          <div className={`esi-search__advanced-search__dte-rep ${selectedSearchOption === searchOptions[2] ? "show" : ""}`}>
            <div className="esi-search__advanced-search__dte-rep__input-container">
              <label>
                <p>Role</p>
                <input type="text" placeholder="Select DTE Role" />
              </label>
              <label>
                <p>First Name</p>
                <input type="text" placeholder="First Name" />
              </label>
              <label>
                <p>Last Name</p>
                <input type="text" placeholder="Last Name" />
              </label>
              <label>
                <p>Email Address*</p>
                <input type="text" placeholder="example@example.com" />
              </label>
            </div>
          </div>

          <div className="esi-search__advanced-search__buttons">
            <ButtonPrimary href="#">Search</ButtonPrimary>
            <button className="link-button">Cancel</button>
          </div>
          <button className="link-button esi-search__advanced-search__basic-btn" onClick={() => setAdvancedSearch(false)}>
            <img
              className="accordion__header__chevron"
              src={chevronIcon}
              alt="chevron"
            />
            Basic Search
          </button>
        </div>
        }

      </Card>
     
      {/* Customer Details / DTE Assignment */}
      <div className="esi-details">
        <div className="esi-details__options">
              {detailsOptions.map(option => {
                return (
                  <div className={selectedDetailsOption === option ? "selected" : ""}>
                    <button
                      className="link-button"
                      onClick={() => {
                        setSelectedDetailsOption(option)
                      }}
                    >
                      {option}
                    </button>
                  </div>
                );
              })}
            </div>
        <Card className="esi-details__results">
          <div className={`esi-details__results__customer-details ${selectedDetailsOption === detailsOptions[0] ? "show" : ""}`}>
            <p>Customer Details</p>
            <div className="esi-details__results__customer-details__content">
              <div className="head">
                <div className="name">Name</div>
                <div className="email">Email</div>
                <div className="phone">Phone</div>
                <div className="bp">BP Number</div>
                <div className="meter">Meter Number</div>
              </div>
              
                <div className="name">John</div>
                <div className="email">johnsmith@email.com</div>
                <div className="phone">(313) 777 - 7777</div>
                <div className="bp">12345679</div>
                <div className="meter">1234567891011</div>
            </div>
          </div>
          <div className={`esi-details__results__dte-assignment ${selectedDetailsOption === detailsOptions[1] ? "show" : ""}`}>
            <p>Current Assignment</p>
            <div className="esi-details__results__dte-assignment__content">
              <div className="role">Account Manager</div>
              <div className="name">Contact Name</div>
              <div className="email">contact@dteenery.com</div>
              <div className="phone">313-123-1234</div>
            </div>
            <p>Additional DTE Contacts</p>
            <div className="esi-details__results__dte-assignment__content">
              <div className="role">Account Manager</div>
              <div className="name">Contact Name</div>
              <div className="email">contact@dteenery.com</div>
              <div className="phone">313-123-1234</div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ESILogInCard;
