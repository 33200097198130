import React from "react";
import CommonApprovalTimelinesModalLink from '../CommonApprovalTimelinesModalLink/CommonApprovalTimelinesModalLink';

export const StaticText = {
  electric: [
    {
      stepID: 'A00',
      stringText: (
        <div>
          DTE Energy has received your request. A DTE representative will
          contact you within 2 business days to discuss your project
          details and service options.
          <br />
          <br />
        </div>
      )
    },
    {
      stepID: "B00",
      /* eslint-disable */
      stringText: (
        <div>
          A DTE Support Specialist will contact you within 2 business
          days to discuss your project details, determine the service
          load necessary and identify any project or site documents
          required to begin your project’s design.
          <br />
          <br />
          Project documents can be emailed or mailed to your Support
          Specialist.
          <br />
          <br />
          {/* <div className="old-indentedText">
            Proof of ownership is a notarized title or deed.
            <br />
            <br />
            <a
              target    = "_blank"
              className = "old-staticATag"
              href      = "https://www.newlook.dteenergy.com/wps/wcm/connect/38eb400f-d39b-445b-a15a-0801a16c3669/sitePlan.pdf?MOD=AJPERES&CACHEID=38eb400f-d39b-445b-a15a-0801a16c3669"
            >
              Site plans
            </a>{" "}
            show the proposed construction location, the location of any
            existing utilities, easements and structures within property
            boundaries.
            <br />
            <br />A{" "}
            <a
              target    = "_blank"
              className = "old-staticATag"
              href      = "https://www.newlook.dteenergy.com/wps/wcm/connect/68ad464f-539e-467d-a597-8f38778d016c/loadLetter.pdf?MOD=AJPERES&CACHEID=68ad464f-539e-467d-a597-8f38778d016c"
            >
              load sheet
            </a>{" "}
            is a list of equipment to be used and the amount of energy each will
            require to function. <br />
            <br />A{" "}
            <a
              target    = "_blank"
              className = "old-staticATag"
              href      = "https://www.newlook.dteenergy.com/wps/wcm/connect/d35be3e4-4512-47cc-a4b1-f5fc1d19373d/riser.pdf?MOD=AJPERES&CACHEID=d35be3e4-4512-47cc-a4b1-f5fc1d19373d]https://www.newlook.dteenergy.com/wps/wcm/connect/d35be3e4-4512-47cc-a4b1-f5fc1d19373d/riser.pdf?MOD=AJPERES&CACHEID=d35be3e4-4512-47cc-a4b1-f5fc1d19373d"
            >
              riser diagram
            </a>{" "}
            is a detailed diagram of your building’s electrical equipment.
          </div> */}
        </div>
      )
      /* eslint-enable */
    },
    {
      stepID: "C00",
      stringText: (
        <div>
          The initial design and site meeting will be scheduled by your
          DTE Planner.
          <br />
          <br />
         </div>
      )
    },
    {
      stepID: "D00",
      /* eslint-disable */
      stringText: (
        <div>
        Your DTE Planner will work on detailed designs for your project
        and request any necessary permits. Your designs will be
        customized based on your service requirements, the location of
        existing utilities and any future development plans for your
        site.
        <br/>
        <br/>
        Please complete all site requirements outlined during
        your site meeting.
        <br />
        <br />
        Note: Requests for redesign work after the original design has
        been completed may impact the project’s schedule. Additional
        permits and approvals may require longer timeframes.
        <br />
        <br />
        Learn more
        about our&nbsp;
        <CommonApprovalTimelinesModalLink
            gasOrElectric={'New Electric Service'}
        />
        {/* &nbsp;<a>common approval timelines.</a> */}
        <br />
        <br />
        <span className="old-textBolder">Designs &amp; Permits</span>
        <br />
        <br />
        Your planner will create detailed plans for your installation
        that may include:
        <br />
        Before you begin digging, you will need the location of
        existing underground utilities to be identified by{' '}
        <a
          target="_blank"
          className="old-staticATag"
          href="http://www.missdig.org/"
        >
          MISS DIG
        </a>
        .<br />
        <br />
        For more information, view our{' '}
        <a
          target="_blank"
          className="old-staticATag"
          href="https://www.newlook.dteenergy.com/wps/wcm/connect/0e5b81ee-a0b6-458e-ba1c-a24735c83035/UndergroundService.pdf?MOD=AJPERES&CACHEID=0e5b81ee-a0b6-458e-ba1c-a24735c83035"
        >
          underground service guidelines
        </a>{' '}
        and/or our
        <a
          target="_blank"
          className="old-staticATag"
          href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders/electric-checklists-guides"
        >
          {' '}
          electric checklists &amp; guides
        </a>
        .
      </div>
      )
    },
    /* eslint-disable */
    {
      stepID: "E00",
      stringText: (
        <div>
          Your DTE Planner will prepare an Agreement for Service
          outlining the design, scope and cost of work for your
          installation.
          {/* Your DTE planner will prepare an Agreement for Service outlining the
          design, scope and cost of work for your installation. Please review,
          approve and attach payment along with a signed easement and return to
          your planner. Payment is due before your job can be scheduled for
          construction.
          <br />
          <br />
          Submit all signed documents to your planner along with a check payable
          to <span className="old-blackTextBolder">DTE Energy</span>. Please
          include your contract number on your check. */}
        </div>
      )
    },
    {
      stepID: "F00",
      stringText: (
        <div>
          Once the municipality’s inspector has approved the work
          required for your site preparation and payment has been
          received, we will inspect your site to make sure all DTE
          Electric standards are met for your service and safety. Your
          DTE planner will complete the final site evaluation.  Once
          approved, we will release the job for construction.
          <br />
          <br />
          Please ensure that your site is ready for inspection by
          completing the following tasks:
          <br />
          <ul>
            <li>Trenching is accessible and free of debris</li>
            <li>All staking is intact and visible</li>
            <li>Path to the site is clear</li>
            <li>Fence is unlocked</li>
            <li>Grade of property is maintained</li>
            <li>Inspection and permit documentation are visible</li>
            <li>Pets are secured and yard is safe to enter</li>
          </ul>
        </div>
      )
    },
    {
      stepID: "G00",
      stringText: (
        <div>
          Once your job is released for construction, a DTE Case Manager will be assigned to your project and coordinate the construction schedule.
          <br />
          <br />
        </div>
      )
    },
    {
      stepID: "H00",
      stringText: (
        <div>
          Meter installation is the final step to energizing your
          service. Once your meter is installed, please verify that the
          site is energized. If you experience any issues with your
          service or if your installation did not meet your expectations,
          please contact your DTE Case Manager.
          <br />
          <br />
        </div>
      )
    }
  ],

  // ---------------------------- gas static
  gas: [
    {
      stepID: "A00",
      stringText: (
        <div>
          DTE Energy has received your request. A DTE representative will
          contact you within 2 business days to discuss your project
          details and service options.
          <br />
          <br />
        </div>
      )
    },
    {
      stepID: "B00",
      /* eslint-disable */
      stringText: (
        <div>
          A DTE representative will contact you within 2 business days to
          discuss your project details, determine the service load
          necessary and identify any project or site documents required
          to begin your project’s design.
          <br />
          <br />
          If you are unsure about any details, please contact a certified plumber to help determine the expected gas load.
          <br />
          <br />
          Project documents can be emailed or mailed to your
          representative.
          <br />
          <br />
          {/*
          <div className="old-indentedText">
            Proof of ownership is a notarized title or deed.
            <br />
            <br />
            <a
              target="_blank"
              className="old-staticATag"
              href="https://www.newlook.dteenergy.com/wps/wcm/connect/8581944e-3b87-4e47-86a4-9e01929f384b/Residential-Natural-Gas-Service-Request.pdf?MOD=AJPERES"
            >
              Site plans
            </a>{" "}
            show the proposed construction location, the location of any
            existing utilities, easements and structures within property
            boundaries.
            <br />
            <br />A{" "}
            <a
              target="_blank"
              className="old-staticATag"
              href="https://www.newlook.dteenergy.com/wps/wcm/connect/ea661245-ee74-4510-82c9-9bf8dac9923a/gasLoadWorksheet.pdf?MOD=AJPERES&ContentCache=NONE"
            >
              load sheet
            </a>{" "}
            is a list of equipment to be used and the amount of energy each will
            require to function. <br />
            <br />
          </div> */}
        </div>
      )
      /* eslint-enable */
    },
    {
      stepID: "C00",
      stringText: (
        <div>
          Your project will be assigned a DTE representative who will
          oversee your project from design to scheduling and
          construction.
          <br />
          <br />
        </div>
      )
    },
    {
      stepID: "D00",
      /* eslint-disable */
      stringText: (
        <div>
          Your DTE representative will work on detailed designs for your
          project and submit any necessary permits. Your designs will be
          customized based on your service requirements, the location of
          existing utilities and any future development plans for your
          site.
          <br />
          <br />
          Please complete all site requirements outlined during your site
          meeting.
          <br />
          <br />
          Note: Requests for redesign work after the original design has
          been completed may impact the project’s schedule. Additional
          permits and approvals may require longer timeframes.
          <br />
          <br />
          Learn more
          about our&nbsp;
          <CommonApprovalTimelinesModalLink
            gasOrElectric={'New Gas Service'}
          />
          <br />
          <br />
        </div>
          // <div>
          //   <DocumentTask type="footing_install">
          //     <p>The footing and foundation - concrete, basement, poured slab, must be poured, and set prior to meter installation.</p>
          //   </DocumentTask>
          //   <DocumentTask type="building_address">
          //     <p>You must provide the building address and/or lot number for new service installation. If you only have a lot number, it must be visible.</p>
          //   </DocumentTask>
          //   <DocumentTask type="grading">
          //     <p>The site must be leveled within about 4 inches of finished grade.</p>
          //   </DocumentTask>
          //   <DocumentTask type="debris_removal">
          //     <p>All obstacles, materials, equipment and debris must be removed from the path of the gas service line and meter location.</p>
          //   </DocumentTask>
          //   <DocumentTask type="meter_location">
          //     <p>Your gas meter location must be clearly marked.</p>
          //   </DocumentTask>
          // </div>
      )
    },
    /* eslint-disable */
    {
      stepID: 'E00',
      stringText: (
        <div>
          Your DTE representative will prepare an Agreement for Service
          outlining the design, scope and cost of work for your
          installation.
          <br />
          <br />
          {/* Please review, approve and attach payment along
          with a signed easement and return to your planner. Payment is
          due before your job can be scheduled for construction.
          <br />
          <br />
          Submit all signed documents to your planner along with a check
          payable to{' '}
          <span className="old-blackTextBolder">DTE Energy</span>. Please
          include your contract number on your check. */}
        </div>
      )
    },
    {
      stepID: 'F00',
      stringText: (
        <div>
            Once DTE Energy approves the work required for your site preparation
            and payment has been received, we will inspect your site to make sure
            all DTE Gas standards are met for your service and safety. Once approved,
            we will release the job for construction.
          <br />
          <br />
          Please ensure that your site is ready for
          inspection by completing the following tasks:
          <br />
          <ul>
            <li>Trenching is accessible and free of debris</li>
            <li>All staking is intact and visible</li>
            <li>Path to the site is clear</li>
            <li>Fence is unlocked</li>
            <li>Grade of property is maintained</li>
            <li>Inspection and permit documentation are visible</li>
            <li>Pets are secured and yard is safe to enter</li>
          </ul>
        </div>
      )
    },
    {
      stepID: 'G00',
      stringText: (
        <div>
          Once your job is released for construction, a DTE
          representative will contact you to coordinate the construction
          schedule.
          {/* Your construction schedule will be developed in order
          to achieve your project’s agreed-upon completion date. */}
          <br />
          <br />
          Note: Completion dates are approximate and may be impacted by
          environmental or weather conditions. Please maintain site
          readiness for the entire scheduled window.
          <br />
          <br />
        </div>
      )
    },
    {
      stepID: 'H00',
      stringText: (
        <div>
          Meter installation is the final step to establishing your
          natural gas service. Once your meter is installed, please
          verify your site is receiving natural gas. If you experience
          any issues with your gas service or if your installation did
          not meet your expectations, please contact your DTE
          representative.
          <br />
          <br />
        </div>
      )
    }
  ]
};
