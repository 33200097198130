import React, { FunctionComponent } from "react";
import Accordion from "../../common/Accordion/Acordion";
import { convertSentenceToIdentifier } from "../../../utilities/utilities";
import "./StepAccordion.scss";

interface StepAccordionProps {
  title              : string;
  subheading         : string;
  icon?              : React.ReactElement;
  iconName           : string;
  className?         : string;
  titleParenthetical?: string;
  stepNumber?        : number;
  children           : any;
  open?              : boolean;
}

const StepAccordion: FunctionComponent<StepAccordionProps> = (
  props: StepAccordionProps
): React.ReactElement => {
  const heading = (
    <React.Fragment>
      <h3 className="step-accordion__title">
        {props.title}
      </h3>
      <span className="step-accordion__parenthetical">
        ({props.titleParenthetical})
      </span>
      <p className="step-accordion__subheading">{props.subheading}</p>
    </React.Fragment>
  );
    
  const icon = (
    <>
      <div
        className={`step-accordion__icon-container__icon icon-container__icon--${convertSentenceToIdentifier(
          props.iconName
        )}`}
      >
        { props.iconName && props.iconName.length ? null :  `${props.iconName} ${" "}` }
        {props.stepNumber}
      </div>
      {/* On mobile, the titles may wrap, creating a gap in the connecting line - this element closes that gap with a border */}
      <div className="step-accordion__icon-container__connecting-line" />
    </>
  );
  return (
    <Accordion
      className={`step-accordion index${JSON.stringify(props.stepNumber) }`}
      icon={icon}
      includeConnectingLine
      heading={heading}
      open={props.open}
      tabIndex
      >
      {props.children}
    </Accordion>
  );
};

export default StepAccordion;
