import React from "react";
import LinkToModal from "../../../common/LinkToModal/LinkToModal";
import UpdateProfileModalContent from "../UpdateProfileInfoModalContent/UpdateProfileInfoModalContent"

const UpdateProfileInfoModalLink: React.FunctionComponent = (): React.ReactElement => {

  return (
    <LinkToModal linkText="Edit" title="Update Profile Information">
      <UpdateProfileModalContent/>
    </LinkToModal>
  );
};

export default UpdateProfileInfoModalLink;
