import React from "react";
import Card from "../common/Card/Card";
import QuestionOrConcernPopover from "../common/QuestionOrConcernPopover/QuestionOrConcernPopover"
import {getCookie} from "../../utilities/cookieUtils"
import "./ContactUsPage.scss";

const ContactUsPage = (): React.ReactElement => {
  document.title = "CMC - Contact Us";

  return (
    <>
      <main className="contact-us-page narrow">
        <h1>Contact Us</h1>
        <Card>
          <h2>Electric Services</h2>
          <p>
            For questions regarding your work orders, phases of construction, site
            maintenance or other items for electric service, please call one of
            the following numbers:
        </p>

          <div className="contact-phone-title">Electric New Business Call Center</div>
          <p>
            <a href="tel:800.338.0178">800.338.0178</a> (Select Option 1)
        </p>

          <hr />

          <div className="contact-phone-title">DTE Electric Northeast Region</div>
          <p>
            <a href="tel:586.783.2026">586.783.2026</a>
          </p>

          <div className="contact-phone-title">DTE Electric Northwest Region</div>
          <p>
            <a href="tel:248.427.2200">248.427.2200</a>
          </p>

          <div className="contact-phone-title">DTE Electric Southeast Region</div>
          <p>
            <a href="tel:313.235.4400">313.235.4400</a>
          </p>

          <div className="contact-phone-title">DTE Electric Southwest Region</div>
          <p>
            <a href="tel:734.397.4321">734.397.4321</a>
          </p>
        </Card>

        <Card>
          <h2>Gas Services</h2>
          <p>
            For questions regarding your work orders, phases of construction, site
            maintenance or other items for gas service, please call one of the
            following numbers:
        </p>

          <div className="contact-phone-title">Gas New Connections Team for Southeast Michigan</div>
          <p>
            <a href="tel:800.338.0178">800.338.0178</a> (Select Option 2)
        </p>

          <hr />

          <div className="contact-phone-title">Gas New Connections Team for Greater Michigan</div>
          <p>
            <a href="tel:800.533.6220">800.533.6220</a>
          </p>
        </Card>
      </main>
      {getCookie("webSecurityToken") && <QuestionOrConcernPopover />}
    </>
  );
};

export default ContactUsPage;
