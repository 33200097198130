import React from "react";
import {withRouter, RouteComponentProps} from 'react-router-dom';
import "./Footer.scss";
import getDomain from "../../services/getDomain";

interface FooterLinkProps {
  label    : string;
  href     : string;
  external?: boolean;
}

const Footer = (props:RouteComponentProps): React.ReactElement => {

  const enableFooter = () => {
    const page = props.location.pathname as string;
    return (page !== "/change-password" && 
    page !== "/reset-password" &&
    page !== "/start-new-service")
  }

  const footerLinks: any = {
    0: [
      {
        label: 'DTE Energy',
        href : `${getDomain()}wps/wcm/connect/dte-web/home`,
      },
      {
        label: 'Builders',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders/builders`,
      },
      {
        label: 'New Construction',
        href : `${getDomain()}wps/wcm/connect/dte-web/home/service-request/residential/moving/new-construction`,
      },
      {
        label: 'Service Map',
        href : `${getDomain()}wps/wcm/connect/dte-web/home/service-request/residential/moving/service-map`,
      },
      {
        label: 'Request Maps',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders/builders-request-maps`,
      },
    ],
    1: [
      {
        label: 'Large Commercial and Industrial',
        href : `${getDomain()}wps/wcm/connect/dte-web/home/billing-and-payments/business/CNI/commercial-and-industrial`,
      },
      {
        label: 'Municipalities',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/municipalities`,
      },
      {
        label: 'Landlord Utility Management',
        href : `${getDomain()}wps/wcm/connect/dte-web/home/billing-and-payments/business/LUM/landlord-utility-management`,
      },
    ],
    2: [
      {
        label   : 'Contact Us',
        href    : '/contact-us',
        external: false,
      },
      {
        label: 'Terms & Conditions',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/footer/terms-and-conditions`,
      },
      {
        label: 'Online Privacy',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/footer/01+online+privacy+policy`,
      },
      {
        label: 'Customer Data Privacy',
        href : `${getDomain()}wps/wcm/connect/dte-web/quicklinks/footer/customer+data+privacy+policy`,
      },
    ],
  };

  const renderLinkList = (column: number) => {
    return (
      <ul className="footer-links-list">
        {footerLinks[column].map((link: FooterLinkProps) => (
          <li key={link.href}>
            <a
              href      = {link.href}
              target    = {`${link.external ? '' : '_blank'}`}
              className = "footer-link"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {enableFooter() && 
      <footer>
        <div className="links-wrapper">
          <div className="list-wrapper">
            {renderLinkList(0)}
          </div>
          <div className="list-wrapper">
            {renderLinkList(1)}
          </div>
          <div className="list-wrapper">
            {renderLinkList(2)}
          </div>
        </div>
        <div className="legal-wrapper">
          &copy; {new Date().getFullYear()} DTE Energy. <span className="no-break">All rights reserved.</span>
        </div>
      </footer>}
    </>
  );
};

export default withRouter(Footer);
