import React from "react";
import LinkToModal from "../LinkToModal/LinkToModal";
import AccessLevelChart from "../../ManageContactsPage/AccessLevelChart/AccessLevelChart";
import AccessLevelChartFuture from "../../ManageContactsPage/AccessLevelChartFuture/AccessLevelChartFuture";

const AccessLevelModalLink: React.FunctionComponent = (): React.ReactElement => {
  return (
    <LinkToModal linkText="View Access Levels" title="Access Levels">
      <h4>Current Access</h4>
      <p>
        Your contacts can utilize specific functions and information for each
        level of access. Administrators have all-access to the site.
      </p>
      <br />
      <AccessLevelChart />
      <br />
      <h4>Future Access</h4>
      <p>
        We are continuously working to improve how builders create and manage
        new electric and/or gas service connections. As we add features to this
        site, your contacts will gain access to the following features and
        information, based on access level.
      </p>
      <br />
      <AccessLevelChartFuture />
    </LinkToModal>
  );
};

export default AccessLevelModalLink;
