import React from "react";
import LinkToModal from "../../../common/LinkToModal/LinkToModal";
import UpdatePasswordModalContent from "../UpdatePasswordModalContent/UpdatePasswordModalContent"

const UpdatePasswordModalLink: React.FunctionComponent = (): React.ReactElement => {

  return (
    <LinkToModal linkText="Change Password" title="Update Password">
      <UpdatePasswordModalContent/>
    </LinkToModal>
  );
};

export default UpdatePasswordModalLink;
