import React, { useState, useEffect, createContext } from "react";
import { LogInResponse } from "../types/logInResponse";
import { hasCookie, getCookie } from "../utilities/cookieUtils";

interface Props {
  id?       : string;
  className?: string;
  children  : JSX.Element | JSX.Element[] | (string | JSX.Element)[];
}

type userSession = null | LogInResponse;

interface UserSessionState {
  userSession   : userSession;
  setUserSession: React.Dispatch<React.SetStateAction<userSession>>;
}

const UserSessionContext = createContext<UserSessionState>({
  userSession   : null,
  setUserSession: () => {}
});

// This prevents auto-logout on page refresh (persists session via cookies)
const persistFromCookies = () => {
  return (hasCookie("webSecurityToken") && hasCookie("displayName"))
  // ...&& token is not expired.  Expand this.
      ? {
        user: {
          displayName: getCookie("displayName"),
          uid        : localStorage.getItem("cmcBusinessId"),
          id         : localStorage.getItem("cmcWebUserId")
        },
      accessToken: { webSecurityToken: getCookie("webSecurityToken") }
      } as LogInResponse
    : null;
}

const UserSessionContextProvider = (props: Props) => {
  const [userSession, setUserSession] = useState<userSession>(persistFromCookies);

  useEffect(() => {
    if (userSession) {
      const now = new Date()
      now.setTime(now.getTime() + 1 * 2700 * 1000)
      document.cookie = `webSecurityToken=${userSession.accessToken.webSecurityToken}; path=/; expires=${now.toUTCString()}`;
      document.cookie = `displayName=${userSession.user.displayName}; path=/; expires=${now.toUTCString()}`;
      // Setting these in localStorage bc they aren't needed for cookie auth
      localStorage.setItem("cmcBusinessId", userSession.user.uid);
      localStorage.setItem("cmcWebUserId", userSession.user.id);
    } else {
      // TODO: add logic to validate cookies and set them if valid instead of removing them all
      const pastDate = 'Thu, 01 Jan 1970 00:00:00 UTC';
      document.cookie = `webSecurityToken=; path=/; expires=${pastDate};`;
      document.cookie = `displayName=; path=/; expires=${pastDate};`;
      localStorage.clear();
      // newlookLogout();
    }
  }, [userSession]);

  return (
    <UserSessionContext.Provider value={{ userSession, setUserSession }}>
      {props.children}
    </UserSessionContext.Provider>
  );
};

export { UserSessionContext, UserSessionContextProvider };
