import React from "react";

// SiteRequirments old content, gas:
              {/* <MarkCompleteTask
                type="footing_install"
                service={SOdetails.serviceType.toLowerCase()}
                status="completed"
                date="12-16-2019"
              />
              <MarkCompleteTask
                type="building_address"
                service={SOdetails.serviceType.toLowerCase()}
                status="in_progress"
                date={moment().subtract(2, 'days')}
              />
              <MarkCompleteTask
                type="grading"
                service={SOdetails.serviceType.toLowerCase()}
                status="not_started"
                date="12-16-2019"
              />
              <MarkCompleteTask
                type="debris_removal"
                service={SOdetails.serviceType.toLowerCase()}
                status="in_progress"
                date={moment().add(2, 'days')}
              />
              <MarkCompleteTask
                type="meter_location"
                service={SOdetails.serviceType.toLowerCase()}
                status="on_hold"
                date="12-16-2019"
              /> */}
          {/* Before you begin digging, you will need the location of existing
              underground utilities to be identified by{" "}
              <a
                target="_blank"
                className="old-staticATag"
                href="http://www.missdig.org/"
              >
                MISS DIG
              </a>
              .<br />
              <br />
              For more information, visit our{" "}
              <a
                target="_blank"
                className="old-staticATag"
                href="https://www.newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders/natural-gas-service"
              >
                natural gas service resources for builders
              </a>
              . */}


export const StaticTaskContent = {
  ApprovalTask: {
    gas:(<p>
      Your DTE representative will review the installation and ensure all DTE Gas standards have been met.
    </p>),
    electric:(<p>
      Your DTE Planner will provide a final site evaluation to ensure all DTE electric standards have been met.
    </p>)
  },
  AgreementTask: {
    gas: (
      <p>
        Please review and approve your Agreement for Service. When approved and signed, return it to your DTE representative.
      </p>
    ),
    electric: (
      <p>
      Please review and approve your Agreement for Service. When approved and signed, return it to your planner. If you have any questions, contact your planner.
    </p>
    )
  },
  DeliverableTask: {
    gas: (<>
      <p>
        Your DTE representative will
        request any municipality permit(s) and provide approvals in order to complete site work.
      </p>
    </>),
    electric: (<>
      <p>
        Your planner will
        request any municipality permit(s) and provide approvals in order to complete site work.
      </p>
    </>)
  },
  DocumentTaskEasement: {
    electric: (
    <p>You will need to provide DTE Energy with an easement. Your DTE Planner will review it and provide approval.
      <br/>A signed copy must be submited to DTE Energy before construction can begin.
    </p>
  ),
  gas: (
    <p>
      You will need to provide DTE Energy with an easement.
      Your DTE representative will review it and provide approval.
      A signed copy must be submited to DTE Energy before construction can begin.
    </p>
  )
},
  SiteRequirements: {
    gas: (<>
        <div>
          <br />
          <span className="old-textBolder">Site Requirements</span>
            <br />
            <br />
            It is your responsibility to complete all site requirements outlined by your DTE representative during the site inspection.
            <br />
            Please contact your DTE representative when the site is prepared and ready for construction.
            <br />
        </div>
    </>),
    electric: (<>
        <div>
          <br />
          <span className="old-textBolder">Site Requirements</span>
          <br />
          <br />
          It is your responsibility to complete all site requirements outlined by your DTE Planner during the site inspection.
          <br />
          Please contact your planner when the site is prepared and ready for construction.
          <br />
          {/* <div className="old-indentedText">
            Municipal inspection(s) for all electrical installations that meet{' '}
            <a
              target="_blank"
              className="old-staticATag"
              href="https://www.nfpa.org/NEC"
            >
              National Electric Code{' '}
            </a>
            and all local ordinances <br />
            <br />
            Trenching
            <br />
            <br />
            Conduit installation
            <br />
            <br />
            Proper installation of metering equipment
            <br />
            <br />
          </div> */}
        </div>
    </>)
  },
  DocumentTask: {
    gas: (<>
          <p>The following documents are required for your project:</p>
          <ul>
            <li>
              <a
                href="https://www.newlook.dteenergy.com/wps/wcm/connect/8581944e-3b87-4e47-86a4-9e01929f384b/Residential-Natural-Gas-Service-Request.pdf?MOD=AJPERES&_ga=2.126579844.861989143.1572453816-2042783985.1565286818"
                target="_blank"
                rel="noopener noreferrer"
              >
                Site plans
              </a>{' '}
              show the proposed construction location, the location of any
              existing utilities, easements and structures within property
              boundaries.
            </li>
            <li>
              A{' '}
              <a
                href="https://www.newlook.dteenergy.com/wps/wcm/connect/ea661245-ee74-4510-82c9-9bf8dac9923a/gasLoadWorksheet.pdf?MOD=AJPERES&ContentCache=NONE&_ga=2.126579844.861989143.1572453816-2042783985.1565286818"
                target="_blank"
                rel="noopener noreferrer"
              >
                load sheet
              </a>{' '}
              is a list of equipment or appliances to be used and the amount of
              energy each will require. The energy required should be provided
              in British thermal units or BTU.
            </li>
            <li>Easement (if applicable)</li>
            <li>Elevated Pressure (EP) Request (if applicable)</li>
            <li>Equipment spec sheet (required for all EP requests)</li>
          </ul>
    </>),
    electric: (<>
      <p>The following documents are required for your project:</p>
      <ul>
        <li>Proof of ownership is a notarized title or deed.</li>
        <li>
          <a
            href="https://www.newlook.dteenergy.com/wps/wcm/connect/38eb400f-d39b-445b-a15a-0801a16c3669/sitePlan.pdf?MOD=AJPERES&CACHEID=38eb400f-d39b-445b-a15a-0801a16c3669"
            target="_blank"
            rel="noopener noreferrer"
          >
            Site plans
          </a>{' '}
          show the proposed construction location, the location of any
          existing utilities, easements and structures within property
          boundaries.
        </li>
        <li>
          A{' '}
          <a
            href="https://www.newlook.dteenergy.com/wps/wcm/connect/68ad464f-539e-467d-a597-8f38778d016c/loadLetter.pdf?MOD=AJPERES&CACHEID=68ad464f-539e-467d-a597-8f38778d016c"
            target="_blank"
            rel="noopener noreferrer"
          >
            load sheet
          </a>{' '}
          is a list of equipment to be used and the amount of energy each
          will require to function.
        </li>
        <li>
          A{' '}
          <a
            href="https://www.newlook.dteenergy.com/wps/wcm/connect/d35be3e4-4512-47cc-a4b1-f5fc1d19373d/riser.pdf?MOD=AJPERES&CACHEID=d35be3e4-4512-47cc-a4b1-f5fc1d19373d"
            target="_blank"
            rel="noopener noreferrer"
          >
            riser diagram
          </a>{' '}
          is a detailed diagram of your building’s electrical equipment.
        </li>
      </ul>
    </>)
  }
}