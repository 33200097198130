import React from "react";
import { Link } from "react-router-dom";
import Card from "../../common/Card/Card";
import getDomain from "../../../services/getDomain";
import FAQAccordion from "../FAQAccordion/FAQAccordion";
import AccessLevelModalLink from "../../common/AccessLevelModalLink/AccessLevelModalLink";
import { UserSessionContext } from "../../../contexts/userSessionContext";

import "./FAQCard.scss";


const FAQCard = () => {
  
  const { userSession } = React.useContext(UserSessionContext);

  // TODO: replace the contents below with actual FAQ content
  return (
    <Card className="faq-card">
      <h2>Frequently Asked Questions</h2>
      {/*<FAQAccordion question="Why do I need to create an account or sign in to view my project's status?">*/}
      {/*  <p>*/}
      {/*    We require all users to register their information and verify their*/}
      {/*    identity to ensure all project information is secure. It is only*/}
      {/*    available to you and those who have access.*/}
      {/*  </p>*/}
      {/*  <p>*/}
      {/*    We require all users to register and verify their identity through*/}
      {/*    positive identification — Social Security number, passport, state ID,*/}
      {/*    etc.*/}
      {/*  </p>*/}
      {/*  <p>*/}
      {/*    You can invite users to view and manage your projects, then determine*/}
      {/*    their level of access. They will need to register with the email*/}
      {/*    address you provide and verify their identity in order to access the*/}
      {/*    portal.*/}
      {/*  </p>*/}
      {/*</FAQAccordion>*/}

      <FAQAccordion question="Can I sign in to the Construction Management Center with my existing DTE Energy utility account?">
        <p>
          Yes, your current username and password accesses all work orders and
          status updates within the Construction Management Center.
          {/*If you don’t*/}
          {/*have an online account, you can{" "}*/}
          {/*<a*/}
          {/*  href={`${getDomain()}commercial/registration/index.jsp`}*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  register*/}
          {/*</a>{" "}*/}
          {/*or <Link to="/">sign in</Link> to your account.*/}
        </p>
      </FAQAccordion>

      <FAQAccordion question="What are the levels of access within the Construction Management Center?">
        <p>
          We have four levels of access throughout this site — administrator;
          view, edit and invite; view and edit; and view only.
        </p>
        <p>
          An administrator has all access.
        </p>
        <p>
          A user with view, edit and invite access can view/edit information 
          within each project, update tasks/statuses, pay bills, upload documents, 
          plus invite new contacts. However, this user will only be allowed to give 
          the same level of access or less, not administrator access.
        </p>
        <p>
          Someone with view and edit access cannot invite new contacts to the project.
        </p>
        <u><AccessLevelModalLink /></u>
      </FAQAccordion>

      <FAQAccordion question="How can I view all my projects?">
        {userSession ? <Link to="/home">Home Page</Link> : 
        
        <>
          <u>
            <Link to="/">Log in</Link>
          </u> to your account and go to the home page.
        </>}
  
      </FAQAccordion>

      <FAQAccordion question="What if I forgot my password?">
        Click{" "}
        <u><a
          href={`${getDomain()}wps/wcm/connect/dte-web/quicklinks/reset-password`}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a></u>{" "}
        to reset your password.
      </FAQAccordion>
    </Card>
  );
};

export default FAQCard;
