import React from "react";
import Card from "../../common/Card/Card"
import UpdateProfileInfoModalLink from "./UpdateProfileInfoModalLink/UpdateProfileInfoModalLink"
import UpdatePasswordModalLink from "./UpdatePasswordModalLink/UpdatePasswordModalLink"
import UpdateEmailModalLink from "./UpdateEmailModalLink/UpdateEmailModalLink"
import "./ProfileInfoCard.scss";
import { formatAddressWithBreak } from '../../../utilities/utilities';
import NumberFormat from 'react-number-format';


const ProfileInfoCard = (): React.ReactElement => {
 const data = {
  name: "woody",
  company: "pixar",
  position: "cowboy",
  phone: "9898241232",
  address: "1418 ARCHWOOD DR, OAKLAND, MI 48363-1705",
  email: "woody@pixar.com"
 }

 const {street, cityStateZip} = formatAddressWithBreak(data.address)

  return (
    <div className="profile-info-card">
      <h3>Profile Information</h3>
      <Card>
        <div className="profile-info-card__header">Personal &amp; Contact Information <UpdateProfileInfoModalLink/></div>
        <div className="profile-info-card__personal-info">
          <div className="info-block">
            <div className="block-line">
              <div className="block-label">
                Name:
                    </div>
              <div className="block-value">
                {data.name}
              </div>
            </div>
            <div className="block-line">
              <div className="block-label">
                Company:
                    </div>
              <div className="block-value">
              {data.company}
              </div>
            </div>
            <div className="block-line">
              <div className="block-label">
                Position:
                    </div>
              <div className="block-value">
              {data.position}
              </div>
            </div>
          </div>
          <div className="info-block">
            <div className="block-line">
              <div className="block-label">
                Phone:
                    </div>
              <div className="block-value">
                <NumberFormat
                  name="phoneValue"
                  type="tel"
                  format="(###) ###-####"
                  placeholder="(   )   -"
                  value={data.phone}
                  displayType="text"
                />
              </div>
            </div>
            <div className="block-line">
              <div className="block-label">
                Billing Address:
                    </div>
              <div className="block-value">
                <address>
                  {street}
                  <br/>
                  {cityStateZip}
                </address>
              </div>
            </div>
          </div>
        </div>
        <span className="profile-info-card__header">Account Information</span>
        <div className="profile-info-card__account-info">
          <div className="info-block">
            <div className="block-line">
              <div className="block-label">
                Email (User ID):
              </div>
              <div className="block-value">
              {data.email} <UpdateEmailModalLink />
              </div>
            </div>
            <div className="block-line">
              <div className="block-label">
                Password:
                    </div>
              <div className="block-value">
                <UpdatePasswordModalLink/>
              </div>
            </div>
          </div>

        </div>
      </Card>
    </div>
  );
};

export default ProfileInfoCard;
