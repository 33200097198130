import React from "react";
import { AccessLevel as AccessLevels } from "../../../types/accessLevel";

interface AccessLevelSelectProps {
  projectID?: string;
  className: string;
  accessLevel: AccessLevels;
  label?: string;
  // TODO: might be able to type this with an actual signature?
  selectHandler: Function;
}

const AccessLevelSelect: React.FC<AccessLevelSelectProps> = ({
  projectID,
  accessLevel,
  label,
  selectHandler,
  className
}) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newAccessLevelValue = event.target.value as AccessLevels;

    if (projectID) {
      selectHandler(projectID, newAccessLevelValue);
    } else {
      selectHandler(newAccessLevelValue);
    }
  };

  const selectID = projectID || "set-default";

  if (accessLevel === "Admin") {
    accessLevel = "Invite";
  }
  const select = (
    <div className="select-wrapper">
      <select
        className={className}
        value={accessLevel}
        onChange={onChangeHandler}
        id={selectID}
        >
        <option value="View">View Only</option>
        <option value="Edit">View &amp; Edit</option>
        <option value="Invite">View, Edit &amp; Invite</option>
      </select>
    </div>
  );

  let content = select;

  if (label) {
    content = (
      // Because we are referencing the select defined above, eslint a11y checks fail
      // We ignore them because we have nested the form control, and we have given it an id
      // eslint-disable-next-line
      <label className={`${className}-label`} htmlFor={selectID}>
        <span className={`${className}-label-text`}>{label}</span>
        {select}
      </label>
    );
  }

  return content;
};

export default AccessLevelSelect;
