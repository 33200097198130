import React from "react";
import ButtonToModal from "../../common/ButtonToModal/ButtonToModal";
// import AddNewContactsModalContent from "../AddNewContactsModalContent/AddNewContactsModalContent";
import InviteContactsModalContent from "../InviteContactsModalContent/InviteContactsModalContent";
import "./ButtonToInviteContactsModal.scss";

const ButtonToAddNewContactsModal: React.FunctionComponent = (): React.ReactElement => {
  return (
    <ButtonToModal
      className          = "add-new-contact-btn"
      title              = "Invite Contact"
      linkText           = "Invite Contact"
      dataTrack          = "CMC"
      dataTrackDetail    = "Invite Contact"
      dataTrackAction    = "click"
      dataTrackSubAction = "attempt"
    >
      <InviteContactsModalContent />
    </ButtonToModal>
  );
};

export default ButtonToAddNewContactsModal;
