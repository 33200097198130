import React from "react";
import "./Task.scss";
import TaskProps from "../../../types/task";

// TO-DO converting background images to react components that render the SVG as HTML
// import { ReactComponent as Completed } from "../../../assets/progressIcons/completed.svg";
// import { ReactComponent as ToDo } from "../../../assets/progressIcons/to-do.svg";
// import { ReactComponent as Pending } from "../../../assets/progressIcons/pending.svg";
// import { ReactComponent as Alert } from "../../../assets/progressIcons/alert.svg";
// import { ReactComponent as Emergency } from '../../../assets/progressIcons/emergency.svg';
// import { ReactComponent as Canceled } from "../../../assets/progressIcons/canceled.svg";
// import { ReactComponent as Future } from "../../../assets/progressIcons/future.svg";
// import { ReactComponent as InProgress } from "../../../assets/progressIcons/in-progress-dot.svg";


// const statusIconComp: { [key:string]: any } = {
// 	completed	: <Completed />,
// 	todo 		: <ToDo />,
// 	pending		: <Pending />,
// 	alert		: <Alert />,
// 	emergency	: <Emergency />,
// 	canceled	: <Canceled />,
// 	future		: <Future />,
// 	in_progress	: <InProgress />
// }

//{statusIconComp[props.icon]} implement where className === status-icon
// ----

const Task: React.FC<TaskProps> = (
    props: TaskProps
): any => {

	const renderHeader = () => {
		return(
			<header className="task-header">
				<div className="status-wrapper">
					<div className="status-icon"  title={props.iconAltText}></div>
				</div>
				<h4 className="header-label">{props.label}</h4>
			</header>
		);
	};

	return(
		<div className={`task-wrapper status-color-${props.statusColor || 'default'} icon-${props.icon || 'default'} ${props.className}`}>
			{renderHeader()}
			<div className="task-content-wrapper">
				{props.children}
				{props.message && (
					<div className="message-wrapper">
						<p>
							<span>{props.message}</span>
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default Task;