import React from "react";
import "./Input.scss";

interface InputProps {
  type: "checkbox" | "radio";
  id?: string;
  className?: string;
  name?: string;
  label?: string;
  labelRight?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onClick?: (e:React.MouseEvent) => void;
  onChange?: (e:React.ChangeEvent) => void;
  htmlFor?: string;
  autoComplete?: "on" | "off";
  value?: string;
  key?: string | number;
}

const Input: React.FunctionComponent<InputProps> = (
  {id, type, name, className, label, labelRight, disabled, checked, onClick, onChange, htmlFor,autoComplete, value, key}
): React.ReactElement => {


  return (
    <label htmlFor={htmlFor} className={`container${type === "checkbox" ? "-square" : ""}`}>
      {!labelRight && label}
        <input
        id={id}
        type={type}
        name={name}
        checked={checked}
        disabled={disabled}
        aria-label={label}
        onClick={(e) => onClick ? onClick(e) : null}
        onChange={(e) => onChange ? onChange(e) : null}
        autoComplete={autoComplete}
        className={className}
        value={value}
        key={key}
      />
      {labelRight && <span className="label-right">{label}</span>}
      <span className={`checkmark${type === "checkbox" ? "-square" : ""}`}></span>
    </label>
  )
};

export default Input;
