import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserSessionContext } from "../../contexts/userSessionContext";
import "./SubNav.scss";
import getDomain from "../../services/getDomain";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";

interface SubNavProps {
  closeHandler: (e:React.SyntheticEvent) => void;
}

const SubNav = (
  props: React.PropsWithChildren<SubNavProps>
): React.ReactElement | null => {
  const { userSession } = useContext(UserSessionContext);

  const closeHandler = props.closeHandler ? props.closeHandler : undefined;

  let navLinksContent = (
    <div className="subnav__limited-links">
      <div>
        <NavLink
          onClick         = {closeHandler}
          to              = "/contact-us"
          activeClassName = "current"
          className = "tab-to-trigger"
          exact
        >
          Contact Us
        </NavLink>
      </div>
      <div>
        <a
          target    = "_blank"
          rel       = "noopener noreferrer"
          className = "tab-to-trigger"
          href      = {`${getDomain()}/wps/wcm/connect/dte-web/home`}
          tabIndex  = {0}
        >
          Go to DTE Energy
        </a>
      </div>
    </div>
  );

  if (userSession) {
    navLinksContent = (
      <div className="subnav__full-links">
        <div>
          <NavLink
            onClick         = {closeHandler}
            to              = "/home"
            activeClassName = "current"
            className = "tab-to-trigger"
            exact
          >
            Home
          </NavLink>
        </div>
        <div>
          <NavLink
            onClick         = {closeHandler}
            to              = "/projects"
            activeClassName = "current"
            className = "tab-to-trigger"
          >
            All Projects
          </NavLink>
        </div>
        <div>
          <NavLink
            onClick         = {closeHandler}
            to              = "/contacts"
            activeClassName = "current"
            className = "tab-to-trigger"
          >
            Contacts
          </NavLink>
        </div>
        <div>
          <NavLink
            onClick         = {closeHandler}
            to              = "/help-resources"
            activeClassName = "current"
            className = "tab-to-trigger"
          >
            Help &amp; Resources
          </NavLink>
        </div>
        <div>
          <NavLink
            onClick         = {closeHandler}
            to              = "/account-settings"
            activeClassName = "current"
            className = "tab-to-trigger"
          >
            My Profile & Notifications
          </NavLink>
        </div>
        {/* <div className="work-order">
          <NavLink
            onClick={closeHandler}
            to="/work-order"
            activeClassName="current"
          >
            Search Work Order
          </NavLink>
        </div> */}
      </div>
    );
  }
  let buttonContainerContent = null;

  if (userSession) {
    buttonContainerContent = (
      <div className="sub-nav__button-container">
        <ButtonPrimary onClick={closeHandler} to="/contact-us">
          Contact Us
        </ButtonPrimary>

        <ButtonPrimary
          onClick = {closeHandler}
          href    = {`${getDomain()}/wps/wcm/connect/dte-web/home`}
          target  = "_blank"
        >
          Go To DTE Energy
        </ButtonPrimary>
      </div>
    );
  }

  return (
    <div className="subnav">
      {navLinksContent}
      {buttonContainerContent}
    </div>
  );
};

export default SubNav;
