import React from "react";
import Card from "../../common/Card/Card";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import getDomain from "../../../services/getDomain";

interface Props {
  className: string;
}

const ChecklistsGuidesCard: React.FunctionComponent<Props> = (
  props: Props
): React.ReactElement => {
  return (
    <Card className={props.className}>
      <h2>Checklists &amp; Guides</h2>
      <p>
        View our checklists and guides to help with planning and installing
        service for your project.
      </p>
      <ButtonPrimary
        href={`${getDomain()}wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders-electric/electric-checklists-guides`}
      >
        Checklists &amp; Guides
      </ButtonPrimary>
    </Card>
  );
};

export default ChecklistsGuidesCard;
