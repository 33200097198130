import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import DayPicker from 'react-day-picker';
import AddToCalendar from 'react-add-to-calendar';
import getDomain from "../../services/getDomain"
import CalendarIcon from "../../assets/icons/calendar-icon.svg";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";
import CalendarIconWhite from "../../assets/icons/calendar-icon-white.svg";
import { formatDate } from '../../utilities/utilities'
import Tooltip from "../common/Tooltip/Tooltip"
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import 'react-day-picker/lib/style.css'
import "./Calendar.scss";
import { cacheHandler } from "../../utilities/cacheUtils";

interface DateAvailableProps{
    date: Date,
    endTime: string,
    slotLength: string,
    startTime: string,
    timeSlot: string,
    vendorName: string
};

const Calendar: React.FC<{
    startDate?       : string;
    endDate?         : string;
    slot?            : string;
    inputCalendar?   : boolean;
    inputDataState?  : { data: string, setData: (args:string|Date) => void }
    inputTime?       : boolean;
    className?       : string;
    dates?           : Array<DateAvailableProps>
    installationNum? : string;
    SOdetails?       : any;
}> = ({
    startDate,
    endDate,
    slot,
    inputCalendar = false,
    inputDataState = null,
    inputTime = false,
    className = "",
    dates,
    installationNum,
    SOdetails
}): any => {{
    const [selectedDay, setSelectedDay] = useState();
    const [selectedTimes, setSelectedTimes] = useState("");
    const [requestTime, setRequestedTime] = useState("");
    const [statusMsg, setStatusMsg] = useState("")

    // these are used for the axios appointment request service and have no logical dependencies
    const [timeSlot, setTimeSlot] = useState("")
    const [vendorName, setVendorName] = useState("")
    const [slotLength, setSlotLength] = useState("")

    const hasOccurred  = moment().isAfter(endDate ? endDate : startDate)
    const hasDateRange = endDate ? true : false;
    const separator    = <span className = "date-separator">&nbsp;-&nbsp;</span>;

    const appointmentRequest = async () => {
        const [startTime, endTime] = [requestTime.split(" - ")[0], requestTime.split(" - ")[1]]
        const url = `${getDomain()}api/construction/usersManagement/v1/users/appointments/serviceOrder/${installationNum}`
        await axios.put( url,  {
                "date": moment(selectedDay).format().toString().slice(0, 10),
                "startTime": startTime,
                "vendorName": vendorName,
                "endTime": endTime,
                "timeSlot": timeSlot,
                "slotLength": slotLength
             }, { withCredentials: true }
          ).then(async (r) => {
              await updateServiceOrderInfo(SOdetails.projectID)
              setRequestedTime("")
              setStatusMsg("Your appointment has been submitted successfully")
            }).catch((e) => {
              console.error(e)
              setStatusMsg("Sorry, an error occurred.")
            })
    }

    const updateServiceOrderInfo = async (SO:string) => {
        const activeBP = localStorage.getItem('cmcActiveBPID')
        axios.get(
            `${getDomain()}api/construction/usersManagement/v1/users/accountSummary/businessId/${activeBP}`,
            { withCredentials: true }
          ).then(r => {
              const projectsList = r.data.projectsList
              let relevantServiceOrder = projectsList.map((p:any) => {
                  return p.serviceOrders.filter((s:any) => {
                      return s.projectID === SO
                  })
              })
              relevantServiceOrder = relevantServiceOrder.filter((rs:any) => rs[0])[0][0]
              let relevantMeterInstallationBlock = relevantServiceOrder.meterInstallation ? relevantServiceOrder.meterInstallation : ""
              if (relevantMeterInstallationBlock) {
                  let existingProjectsList = localStorage.getItem('cmcProjectsList') || "[]"
                  let parsedExistingProjectsList = JSON.parse(existingProjectsList)
                  let updatedProjectsList = parsedExistingProjectsList.map((p:any) => {
                      let updatedServiceOrders = p.serviceOrders.map((s:any) => {
                          if (s.projectID === SO) {
                              if (s.meterInstallation) {
                                relevantMeterInstallationBlock.updated = 'yes'
                                // relevantMeterInstallationBlock.status = "In Progress"
                                // relevantMeterInstallationBlock.date = "2020-04-01"
                                s.meterInstallation = relevantMeterInstallationBlock
                                let relevantSummaryData = p.summaryData.map((sd:any) => {
                                    if (sd.projectID === SO) {
                                        sd.meterInstallation = relevantMeterInstallationBlock
                                    }
                                    return sd
                                })
                                if (relevantSummaryData) {
                                    p.summaryData = relevantSummaryData
                                }
                                // Earlier, riskier solution:
                                // p.summaryData[0].meterInstallation = relevantMeterInstallationBlock
                              }
                          }
                          return s
                      })
                      p.serviceOrders = updatedServiceOrders
                      return p
                  })
                  cacheHandler.set('ProjectsList', JSON.stringify(updatedProjectsList))
              }
          })
    }

    interface selectBoxValues {
        type: string,
        props: { value: string, selected: boolean, disabled: boolean },
        innerHTML: string
    }

    const iterateSelect = (arr: Array<selectBoxValues>) => {
        return (arr.map((obj) => {
            const { type, props, innerHTML } = obj;
            const el = React.createElement(type, props, innerHTML);
            return el;
        }))
    }
    // below's format "AM_08-00-00 - 12-00-00_04-00" is used and parsed for the post response, changing this requires a change to the POST
    const getSelectTimeSlots = (slot: string) => {
        switch(slot) {
            case "08-00-00 - 12-00-00":
                return [
                    { type: "option", props: { value: "", selected: false, disabled: true }, innerHTML: "Select Time Slot"},
                    { type: "option", props: { value: "AM_08-00-00 - 12-00-00_04-00", selected: false }, innerHTML: "AM (8:00 AM – 12:00 PM)" },
                  ];
            case "12-00-00 - 16-29-00":
                return [
                    { type: "option", props: { value: "", selected: false, disabled: true }, innerHTML: "Select Time Slot"},
                    { type: "option", props: { value: "PM_12-00-00 - 16-29-00_04-29", selected: false }, innerHTML: "PM (12:00 PM – 4:30 PM)" },
                  ];
            case "08-00-00 - 16-29-00":
                return [
                    { type: "option", props: { value: "", selected: false, disabled: true }, innerHTML: "Select Time Slot"},
                    // { type: "option", props: { value: "08-00-00 - 16-29-00", selected: false }, innerHTML: "Any Time (8:00 AM–4:30 PM)" },
                    { type: "option", props: { value: "AM_08-00-00 - 12-00-00_04-00", selected: false }, innerHTML: "AM (8:00 AM – 12:00 PM)" },
                    { type: "option", props: { value: "PM_12-00-00 - 16-29-00_04-29", selected: false }, innerHTML: "PM (12:00 PM – 4:30 PM)" },
                  ];
        }
    };

    const event = {
        title      : 'Meter Installation',
        description: 'A DTE crew will install your meter, connecting your meter and fuel line to the service line',
        startTime  : moment(startDate).hour(8).minute(0).format(),
        endTime    : moment(endDate).hour(16).minute(0).format(),
        location   : ''
    };

    const setResponseData = (date: DateAvailableProps) => {
        setSelectedTimes(`${date.startTime} - ${date.endTime}`)

        setTimeSlot(date.timeSlot);
        setSlotLength(date.slotLength);
        setVendorName(date.vendorName);
    }

    const handleDayClick = (day: Date) => {

        if(day !== selectedDay && dates !== undefined && moment(day).isBusinessDay()) {
            let found = false;
            let datesHandler = dates.filter((obj: any) => {
                let compareDate = new Date(obj.date+"T18:13:45.650Z").toString().slice(0, 15);
                if(compareDate === day.toString().slice(0, 15)) {
                    if(!found) {
                        found = true;
                    }
                    return obj;
                }
            }) as DateAvailableProps[];

            if(found) {
                if(datesHandler.length > 1) {
                    setSelectedDay(day);
                    if(datesHandler[0].startTime === "08-00-00") {
                        setRequestedTime(`${datesHandler[0].startTime} - ${datesHandler[0].endTime}`)

                        setResponseData({
                            date: datesHandler[0].date,
                            startTime: datesHandler[0].startTime,
                            endTime: datesHandler[1].endTime,
                            slotLength: datesHandler[0].slotLength,
                            timeSlot: datesHandler[0].timeSlot,
                            vendorName: datesHandler[0].vendorName
                        })
                    } else if(datesHandler[0].startTime === "12-00-00") {
                        setRequestedTime(`${datesHandler[1].startTime} - ${datesHandler[1].endTime}`)

                        setResponseData({
                            date: datesHandler[1].date,
                            startTime: datesHandler[1].startTime,
                            endTime: datesHandler[0].endTime,
                            slotLength: datesHandler[1].slotLength,
                            timeSlot: datesHandler[1].timeSlot,
                            vendorName: datesHandler[1].vendorName
                        })
                    }
                } else {
                    setSelectedDay(day);
                    setResponseData(datesHandler[0]);
                    if(inputDataState){
                        inputDataState.setData(day);
                    }
                }
            }

        } else {
            if(inputDataState){
                setSelectedDay(day);
                inputDataState.setData(day);
            }
        }
    };

    const calendarDateSelector = () => {
        if(dates && dates.length) {
            let arr = dates.map((obj) => {
                if(moment(obj.date+"T18:13:45.650Z").isBusinessDay()) {
                    return new Date(obj.date+"T18:13:45.650Z")
                }
            })
            const modifiers = { highlighted: arr }
            return (
                <DayPicker
                className="select-limited-date"
                onDayClick={handleDayClick}
                selectedDays={selectedDay}
                showOutsideDays={true}
                modifiers={modifiers}
                />)
        } else {
            return <DayPicker
            onDayClick={handleDayClick}
            selectedDays={inputDataState && inputDataState.data.length ? inputDataState.data : selectedDay}
            showOutsideDays={true}
            disabledDays={ { before: new Date() } }
            />
        }
    }

    const calendarDateToLoad = () => {
        if(inputDataState && inputDataState.data.length) {
            let mm = inputDataState.data.split("-")[1];
            let datesToShortHand = [ "01", "02", "08", "08", "09", "10", "11", "12" ];
            if(datesToShortHand.includes(mm)) {
                return moment(inputDataState.data).format('MMM. Do, YYYY');
            } else {
                return moment(inputDataState.data).format('MMMM Do, YYYY');
            }
        } else if(selectedDay) {
            let mm = moment(selectedDay).format("L").split("/")[0];
            let datesToShortHand = [ "01", "02", "08", "08", "09", "10", "11", "12" ];
            if(datesToShortHand.includes(mm)) {
                return moment(selectedDay).format('MMM. Do, YYYY');
            } else {
                return moment(selectedDay).format('MMMM Do, YYYY');
            }
        } else {
            return;
        }
    }

    return (
        <>
        {inputCalendar ?
        <div className="calendar-widget-wrapper">
            <div className={`calendar-display-wrapper ${className}`}>
                <div className={`calendar-bar-wrapper ${className}`}>
                    <input className="white-calendar-date-wrapper" placeholder="Select Date" value={calendarDateToLoad()} />
                    <Tooltip tooltipBody={calendarDateSelector()} eventTrigger="click" className="calendar" selectedDay={selectedDay}>
                        <div className="white-calendar-icon-wrapper">
                            <img src={CalendarIconWhite} title="Scheduled date" alt="Scheduled date" />
                        </div>
                    </Tooltip>
                </div>
                { inputTime &&
                <>
                    <div className="select-wrapper">
                        <select className="select-calendar-bar-wrapper"
                        onClick={(e) => {
                            setTimeSlot(e.currentTarget.value.split("_")[0]);
                            setRequestedTime(e.currentTarget.value.split("_")[1]);
                            setSlotLength(e.currentTarget.value.split("_")[2])  }
                            }>
                            { selectedTimes.length ? (
                                <>
                                {iterateSelect(getSelectTimeSlots(selectedTimes) as any)}
                                </>
                            ) : (
                                <option value="" disabled selected>Select Time Slot</option>
                            )}
                        </select>
                    </div>
                    <ButtonPrimary
                        onClick={() => { appointmentRequest() }}
                        className={"schedule-date-btn"}
                        disabled={ requestTime && requestTime.length ? false : true}
                    >Schedule
                    </ButtonPrimary>
                    <div style={{minWidth:"300px",padding:"1rem"}}>{statusMsg}</div>

                </>
                }
            </div>
        </div>
        :
        <div className="calendar-widget-wrapper">
            <div className="display-wrapper">
                <div className="bar-wrapper">
                    <div className="icon-wrapper">
                        <img src={CalendarIcon} title="Scheduled date" alt="Scheduled date" />
                    </div>
                    <div className="date-wrapper">
                        <span className="day-wrapper">
                            {!hasDateRange && startDate && formatDate(startDate)}
                            {hasDateRange && separator}
                        </span>
                        {hasDateRange && (
                        <span className="day-wrapper">
                            {endDate && formatDate(endDate)}
                        </span>
                        )}
                        <span className="time-wrapper">
                            {slot}
                        </span>
                    </div>
                    {!hasOccurred && (
                        <div className="controls-wrapper">
                            <AddToCalendar
                                event              = {event}
                                buttonLabel        = "Add to Calendar"
                                rootClass          = "add-to-calendar-wrapper"
                                dropdownClass      = "dropdown-wrapper"
                                buttonWrapperClass = "calendar-button-wrapper"
                                buttonClassClosed  = "closed"
                                buttonClassOpen    = "open"
                            />
                        </div>
                    )}
                </div>
            </div>
            {!hasOccurred &&
                <div className="note-wrapper">
                    <p>{"If this time is no longer convenient, please contact your DTE representative to reschedule."}</p>
                </div>
            }
        </div>
        }
        </>
    );
}}

export default Calendar;