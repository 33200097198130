import React, { useContext, useCallback } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { UserSessionContext } from "../../contexts/userSessionContext";
import { useDispatch } from "../App/App"

interface PrivateRouteProps {
    component: React.FC<RouteComponentProps>;
    path?    : string;
}

const PrivateRoute = (
  props: PrivateRouteProps
  ): React.ReactElement => {
    const dispatch = useDispatch();
    const setPreviousHistory = useCallback((data) => dispatch({ type: 'setPreviousHistory', previousHistory: data }), [dispatch]);
    const { userSession } = useContext(UserSessionContext);

    // @TODO: Add 404 handling for cases like /contacts/does-not-exist
    !userSession && setPreviousHistory(props.path);
    return userSession ? <Route exact path={props.path} component={props.component} /> : <Redirect to={'/'} />;
}

export default PrivateRoute;