import React, {ReactElement, ReactNode} from 'react';
import Task from './Task';

const DeliverableTask: React.FC<{
    status: "Not Started" | "In Progress" | "Completed";
    label?: string;
    type?: string;
    children?: ReactNode;
}> = ({
    status,
    type= "municipal_permit",
    label= "Municipality Permits",
    children,
}): ReactElement => {{

    const statusKey = status.replace(' ', '_').toLowerCase() || "not_started"

    const statuses: any = {
        not_started: {
            color: "grey",
            icon: "future",
            altText: "Future",
            message: `Your municipality permit(s) have not been requested.`
        },
        in_progress: {
            color: "default",
            icon: "in_progress",
            altText: "To-Do",
            message: `Your municipality permit(s) have been requested.`
        },
        completed: {
            color: "green",
            icon: "completed",
            altText: "Completed",
            message: `Your municipality permit(s) have been received and approved.`
        }
    }

    return(
        <Task
            className   = "deliverable-task"
            statusColor = {statuses[statusKey].color}
            status      = {statusKey}
            label       = {label}
            message     = {statuses[statusKey].message}
            icon        = {statuses[statusKey].icon}
            iconAltText = {statuses[statusKey].altText}
        >
            {children}
        </Task>
    );
}};

export default DeliverableTask;
