import React from "react";
import Card from "../../common/Card/Card";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";

interface Props {
  className: string;
}

const HelpAndResourcesCard: React.FunctionComponent<Props> = (
  props: Props
): React.ReactElement => {
  return (
    <Card className={props.className}>
      <h2>Help &amp; Resources</h2>
      <p>
        We are here to help you during each phase of your construction projects.
        Use our Help &amp; Resources page to learn how to navigate this website,
        start your project, manage each phase, complete tasks and keep your
        project on track until completion.
      </p>
      <ButtonPrimary to="/help-resources">Help &amp; Resources</ButtonPrimary>
    </Card>
  );
};

export default HelpAndResourcesCard;
