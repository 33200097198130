import React from "react";
import ButtonToModal from "../../common/ButtonToModal/ButtonToModal";
import AddNewContactsModalContent from "../AddNewContactsModalContent/AddNewContactsModalContent"
import "./AddToProjectButton.scss";


const AddToProjectButton = (props: any) => {
  return (
    <ButtonToModal
      className={"invite-to-project-btn " + props.className}
      title="+ Add to Project(s)"
      linkText="+Add to Project(s)"
      disabled={props.disabled}
    >
      <AddNewContactsModalContent
        addToProject={props.addToProject}
        projects={props.projects}
      />
    </ButtonToModal>
  );
};

export default AddToProjectButton;
