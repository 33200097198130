import { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

const ScrollToTopWhenLocationChanges = (props: RouteComponentProps): null => {
  useEffect((): void => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return null;
};

export default withRouter(ScrollToTopWhenLocationChanges);
