import React, { useState, ChangeEvent } from "react";
import axios from "axios";
import Card from "../common/Card/Card";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";
import "./ResetPasswordPage.scss";
import getDomain from "../../services/getDomain";

const ResetPasswordPage = (): React.ReactElement => {
  document.title = "CMC - Reset Your Password";

  const userDefault = {
    email: "email@email.com",
    newPassword: "",
    confirmPassword: ""
  };
  const [user, setUser] = useState(userDefault);
  const [strength, setStrength] = useState(0)
  const [confirmError, setConfirmError] = useState(false)
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const requestChange = () => {
    axios
    .post(
      `${getDomain()}api/user/resetpassword`,
      {
        url: "/api/user/resetpassword",
        password: user.newPassword,
        id:1,
        token:"gotta get the token"
        
      },
      { withCredentials: true }
    )
    .then(r => {
      console.log("- resp", r.data);
      setSuccess(true);
    })
    .catch(e => {
      console.log("- error", e);
      setError(true);
    });
  }

  // consider https://github.com/dropbox/zxcvbn for password strength checking?
  const checkPasswordStrength = () => {
    const str: string = user.newPassword;
    let strength = 0;
    if (/[a-z]/.test(str)) strength += 1;
    if (/[A-Z]/.test(str)) strength += 1;
    if (/[0-9]/.test(str)) strength += 1;
    if (str.length >= 8) strength += 1;
    setStrength(strength)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser(prevState => ({ ...prevState, [name]: value }));
    checkPasswordStrength();
  };

  const confirmHandler = () => {
    if (user.newPassword !== user.confirmPassword) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  };

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute("required", "true");
  };

  const SuccessMessage = (<>
    <h4>Your password has been changed!</h4>
    <p>A password change confirmation has ben sent to you at {user.email}</p>
    <hr/>
    <ButtonPrimary href="/">I'm Done</ButtonPrimary>
  </>)

  return (
    <div className="reset-password-page narrow">
      <main>
        <Card>
          {success ? SuccessMessage : 
          <>
          <h4>Create a New Password</h4>
          <p>Email Address:</p>
          <p className="reset-password-page__user-email">{user.email}</p>
          <div className="reset-password-page__input-container">
            <label>
              New Password*
              <input
                type="password"
                placeholder="New Password"
                name="newPassword"
                value={user.newPassword}
                onChange={handleChange}
                onKeyUp={checkPasswordStrength}
                onBlur={blurHandler}
                maxLength={25}
              />
            </label>
            <label>
              Confirm Password*
              <input
                type="password"
                placeholder="New Password"
                name="confirmPassword"
                value={user.confirmPassword}
                onChange={handleChange}
                onKeyUp={confirmHandler}
                onBlur={blurHandler}
                maxLength={25}
              />
              <span className="confirm-error">
                {confirmError && "Passwords do not match."}
              </span>
            </label>
          </div>
          <div className="reset-password-page__password-strength">
            <p>Password Strength</p>
            <meter min="0" max="4" value={strength} />
            <p>Password must include at least:</p>
            <ul>
              <li className={(/[0-9]/.test(user.newPassword)) ? "checked" : ""}>1 Number</li>
              <li className={(/[a-z]/.test(user.newPassword)) ? "checked" : ""}>1 Lowercase letter</li>
              <li className={(/[A-Z]/.test(user.newPassword)) ? "checked" : ""}>1 Uppercase letter</li>
              <li className={user.newPassword.length >= 8 ? "checked" : ""}>8-25 Characters</li>
            </ul>
          </div>
          <hr />
          <ButtonPrimary 
            onClick={() => requestChange()}
            disabled={strength !== 4 || user.confirmPassword.length === 0 || confirmError === true}>
            Reset Password
          </ButtonPrimary>
          </>}
        </Card>
        <p>
          If you have any questions or concerns, please contact us at
          800.338.0178.
        </p>
      </main>
    </div>
  );
};
export default ResetPasswordPage;
