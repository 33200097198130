import React from "react";
import LinkToModal from "../../common/LinkToModal/LinkToModal";

const CommonApprovalTimelinesModal: React.FC<{ gasOrElectric: string }> = ({
  gasOrElectric
}): React.ReactElement => {
  const timeframeBullets =
    gasOrElectric === "New Electric Service" ? (
      <ul>
        <li>Municipality approvals, 2 to 4 weeks</li>
        <li>Tree or shrub removal, 1 to 2 weeks</li>
        <li>Railway crossings, 6 to 12 months</li>
        <li>Land easements, 6 to 8 weeks</li>
      </ul>
    ) : (
      <ul>
        <li>Municipality approvals, 2 to 4 weeks</li>
        <li>
          Environmental approvals, e.g., soil erosion permit from the Department
          of Environmental Quality, 1 to 4 months
        </li>
        <li>Railway crossings, 6 to 12 months</li>
      </ul>
    );

  return (
    <LinkToModal
      title="common approval timelines"
      linkText="common approval timelines."
    >
      <p>
        Certain permits and approvals may require additional timeframes that
        could impact your project’s schedule.
      </p>

      <div>
        Common approval requirements and timeframes:
        {timeframeBullets}
      </div>

      <p>
        Meeting the deadline for this project depends on completing the
        requirements of each phase before moving to the next phase. In addition,
        weather could be a factor in changing the schedule.
      </p>

      <p>
        Contact your DTE planner if you have any questions about the
        requirements and approvals for your project.
      </p>
    </LinkToModal>
  );
};

export default CommonApprovalTimelinesModal;
