import React from "react";
import LinkToModal from "../../../common/LinkToModal/LinkToModal";
import UpdateEmailModalContent from "../UpdateEmailModalContent/UpdateEmailModalContent"

const UpdateEmailModalLink: React.FunctionComponent = (): React.ReactElement => {

  return (
    <LinkToModal linkText="Update" title="Update Email Address (User ID)">
      <UpdateEmailModalContent/>
    </LinkToModal>
  );
};

export default UpdateEmailModalLink;
