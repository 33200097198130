import React from "react";
import "./ProfileNotifications.scss"
import checkMarkIcon from "../../../../assets/checkmark.svg";
import Input from "../../../common/Input/Input"

interface Props {
  editing: boolean;
}

const ProfileNotifications: React.FunctionComponent<Props> = (
  props: Props
): React.ReactElement => {
  const checkMark = <img src={checkMarkIcon} alt="check mark" />;
  const contactTypes = ["Email", "Text", "Recent"]


  return (
    <>
      <h4>Profile Notifications</h4>
      <span>Manage how you would like to be notified when specific profile changes occur.</span>
      <br />
      <br />
      <div className="profile-settings">
        <div className="profile-settings__heading">
          <h5>Profile Settings</h5>
        </div>
        <div className="profile-settings__type">
          {contactTypes.map(contactType => {
            return <div><h5>{contactType}</h5></div>;
          })}
        </div>
        <div className="profile-settings__heading">
          <p>Profile Change - Notification when a change is made to your profile settings.</p>
        </div>
        <div className="profile-settings__type">
          {contactTypes.map(contactType => {
            return (
              <div className="input">

                {!props.editing ? (
                  <p>{checkMark}</p>
                ) : (
                    <Input
                      type="checkbox"
                      onClick={() => {}}
                    />

                  )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default ProfileNotifications;
