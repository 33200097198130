import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
  ChangeEvent
} from "react";
import NumberFormat from "react-number-format";
import { WorkOrder } from "../../../../types/workOrder";
import { projectsListItem } from "../../../../types/getProjectsResponse";
import { ProjectListItemProps } from "../../../../types/ProjectListItem";
import { cacheHandler } from "../../../../utilities/cacheUtils";
import { formatAddress } from "../../../../utilities/utilities";
import { ModalContext } from "../../../../contexts/modalContext";
import { UserSessionContext } from "../../../../contexts/userSessionContext";
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";
import Input from "../../Input/Input";
import "./ContactSupervisorModalContent.scss";

interface ContactSupervisorModalContentProps {
  address?: string;
  workOrder?: string;
}
interface initialStateProps {
  project: string;
  workOrders: any[any];
  issue: string;
  issueDescription: string;
  email: string;
  emailError: boolean;
  phone: string;
  phoneError: boolean;
}

const ContactSupervisorModalContent = ({
  address,
  workOrder
}: ContactSupervisorModalContentProps) => {

  const accountData = JSON.parse(cacheHandler.get("AccountData") || "[]");
  const initialState: initialStateProps = {
    project: address || "",
    workOrders: [workOrder|| null],
    issue: "",
    issueDescription: "",
    email: "",
    emailError: false,
    phone: "",
    phoneError: false
  };
  const [
    { project, workOrders, issue, issueDescription, email, phone, phoneError },
    setState
  ] = useState(initialState);
  const [formReady, setFormReady] = useState(false);
  const [associatedWorkorders, setAssociatedWorkorders] = useState();
  const [modalOverlay, setModalOverlay] = useState(false);
  const { setModalOpen } = useContext(ModalContext);
  const { userSession } = useContext(UserSessionContext);
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const emailAddress = (userSession && userSession.user.id) || "";
    setState(prevState => ({ ...prevState, email: emailAddress }));
  }, []);

  useEffect(() => {
    if (project) {
      getAssociatedWorkorders();
    }
    if (project && issue && email && phone) {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  }, [project, issue, email, phone]);

  const getAssociatedWorkorders = () => {
    if (accountData) {
      let foundProject: ProjectListItemProps[];
      if (!project) {
        return null;
      }
      foundProject = accountData.projectsList.filter((p:any) => formatAddress(project) === formatAddress(p.projectAddress));
      foundProject[0] && setAssociatedWorkorders(foundProject[0].workOrders);
    }
  };

  const changeHandler = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
    >
  ) => {
    if (event.type === "change") {
      const { name, value } = event.target;
      setState(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const inputChangeHandler = (value: string) => {
    let workOrdersArray = [...workOrders];
    const index = workOrdersArray.indexOf(value);
    if (index > -1) {
      workOrdersArray.splice(index, 1);
    } else {
      workOrdersArray.push(value);
    }
    setState(prevState => ({ ...prevState, workOrders: workOrdersArray }));
  };

  const clearInputs = useCallback(() => {
    setState({ ...initialState });
  }, [initialState]);

  const cancelHandler = () => {
    setModalOpen(false);
    clearInputs();
  };

  const emailBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.type === "blur") {
      event.target.setAttribute("required", "true");
    }
  };

  const phoneBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute("required", "true");
    const phoneNum = phone.replace(/[- )(]/g, "");
    if (phoneNum.length === 10 || phoneNum.length === 0) {
      setState(prevState => ({ ...prevState, phoneError: false }));
    } else {
      setState(prevState => ({ ...prevState, phoneError: true }));
    }
  };

  // const isOtherInputSelected = () => {
  //   const input = document.getElementById("other-input") as HTMLInputElement;
  //   if (!input) {
  //     return true
  //   }
  //   return !input.checked
  // }

  const defaultConfirmationModal = (
    <div className={modalOverlay ? "overlay" : "no-overlay"}>
      <h2>Request Submitted</h2>
      <div className="">
        Your request was successfully submitted. A supervisor will contact you
        within 24 hours.
      </div>
      <ButtonPrimary
        className=""
        onClick={() => {
          setModalOpen(false);
        }}
      >
        Close
      </ButtonPrimary>
    </div>
  );

  const contactSupervisorContent = (
    <div
      className={`contact-supervisor-modal ${
        modalOverlay ? "content-overlay" : ""
      }`}
    >
      <p>
        If you are experiencing a repeated problem or are dissatisfied with the
        level of service you are receiving with your new construction project,
        please fill out the form below and a supervisor will contact you within
        24 hours.
        <br />
        <br />
        If your matter is urgent, please{" "}
        <a href="#">contact your regional service center.</a>
      </p>
      <h4>Tell Us About Your Issue Or Concern</h4>
      <div className="contact-supervisor-modal__form">
        <label>
          Please select the project you need help with.*
          <div className="select-wrapper">
            <select
              name="project"
              value={project}
              onChange={e => {
                changeHandler(e);
                setState(prevState => ({ ...prevState, workOrders: [] }));
              }}
            >
              <option value="" selected={!project} disabled>
                Select a Project
              </option>
              {accountData.projectsList &&
                accountData.projectsList.map((p: projectsListItem) => {
                  const { projectAddress } = p;
                  if (formatAddress(project) === projectAddress) {
                    return (
                      <option
                        value={projectAddress}
                        selected
                        key={projectAddress}
                      >
                        {projectAddress}
                      </option>
                    );
                  } else {
                    return (
                      <option value={projectAddress} key={projectAddress}>
                        {projectAddress}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
        </label>

        {associatedWorkorders && (
          <>
            <div className="inputs-wrapper">
              {associatedWorkorders.map((wo: WorkOrder) => {
                return (
                  <Input
                    type="checkbox"
                    checked={workOrders.indexOf(wo.PWO) > -1}
                    onClick={(event: any) =>
                      inputChangeHandler(event.target.value)
                    }
                    label={`${wo.PWO} - ${wo.service_type_label}`}
                    value={wo.PWO}
                    htmlFor={wo.PWO}
                    id={wo.PWO}
                    labelRight
                    key={wo.PWO}
                  />
                );
              })}
            </div>{" "}
          </>
        )}

        <label>
          Select the subject of your issue or concern.*
          <div className="select-wrapper">
            <select
              name="issue"
              value={issue}
              onChange={e => {
                changeHandler(e);
              }}>
              <option value="" selected disabled>
                Project Documents
              </option>
              <option value="another option">Another option</option>
            </select>
          </div>
        </label>

        <textarea
          name="issueDescription"
          value={issueDescription}
          placeholder="Describe your issue or concern."
          onChange={e => {
            changeHandler(e);
          }}
        >
          {issueDescription}
        </textarea>
      </div>
      <h4>Contact Details</h4>
      <p>
        Please confirm your contact information information where you can be
        reached regarding this issue.
      </p>
      <div className="contact-supervisor-modal__form">
        <label>
          Email Address*
          <input
            type="email"
            name="email"
            value={email.toLowerCase()}
            onChange={e => {
              changeHandler(e);
            }}
            onBlur={e => {
              emailBlurHandler(e);
            }}
            ref={emailInputRef}
          />
          <div className="error email-error" data-testid="email-error">
            Please enter a valid email address. (ex: name@email.com)
          </div>
        </label>
        <label>
          Phone*
          <NumberFormat
            name="phone"
            type="tel"
            format="(###) ###-####"
            placeholder="(   )   -"
            value={phone}
            onChange={e => {
              changeHandler(e);
            }}
            onBlur={e => {
              phoneBlurHandler(e);
            }}
            id={phoneError ? "phone-error" : ""}
          />
          {phoneError && (
            <div className="other-error" data-testid="email-error">
              Phone number is incomplete.
            </div>
          )}
        </label>
      </div>

      <div className="contact-supervisor-modal__buttons">
        <ButtonPrimary
          onClick={() => {
            setModalOverlay(true);
          }}
          disabled={!formReady}
        >
          Submit
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            cancelHandler();
          }}
        >
          Cancel
        </ButtonPrimary>
      </div>
    </div>
  );

  return (
    <>
      {defaultConfirmationModal}
      {contactSupervisorContent}
    </>
  );
};

export default ContactSupervisorModalContent;
