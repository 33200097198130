import React from "react";
import ButtonToModal from "../../ButtonToModal/ButtonToModal";
import ContactSupervisorModalContent from "./ContactSupervisorModalContent";
import "./ButtonToContactSupervisorModal.scss"

const ButtonToContactSupervisorModal: React.FC<{ address?: string, workOrder?: string }> = ({
  address, workOrder
}): React.ReactElement => {
  return (
    <ButtonToModal
      className          = "contact-supervisor-btn"
      title              = "Contact a Supervisor"
      linkText           = "Contact a Supervisor"
      dataTrack          = "CMC"
      dataTrackDetail    = "Contact a Supervisor"
      dataTrackAction    = "click"
      dataTrackSubAction = "attempt"
    >
      <ContactSupervisorModalContent address={address || undefined} workOrder={workOrder || undefined}/>
    </ButtonToModal>
  );
};

export default ButtonToContactSupervisorModal;
