import React, { useContext, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserSessionContext } from "../../contexts/userSessionContext";
import { cacheHandler } from "../../utilities/cacheUtils";
import GuestWorkOrderPage from "../WorkOrderPage/GuestWorkOrderPage";
import WorkOrderProcessCard from "../common/WorkOrderProcessCard/WorkOrderProcessCard";
import { StaticModal } from "../common/StaticModal/StaticModal"
import ContactUsCard from "../common/ContactUsCard/ContactUsCard";
import HowItWorks from "../common/HowItWorksCard/HowItWorksCard";
import HelpAndResourcesCard from "./HelpAndResourcesCard/HelpAndResourcesCard";
import WelcomeCard from "./WelcomeCard/WelcomeCard";
import LogInCard from "./LogInCard/LogInCard";
import "./LandingPage.scss";

const LandingPage = (props: RouteComponentProps): React.ReactElement => {
  document.title = "CMC - Landing Page";
  const { userSession } = useContext(UserSessionContext);
  const [guestSearchWON, setGuestSearchWON] = useState("")

  // let guestSearch = <div>Guest Search: { guestSearchWON }</div>;
  // guestSearch = <WorkOrderPage isGuestSearch={true} initialNum={guestSearchWON} match={{params: { num: {guestSearchWON}}}}/>

  const guestSearch = () => {
    // props.history.push("#"+guestSearchWON)
    // window.location.hash=guestSearchWON;
    console.log('running guest search')
    return (
      <GuestWorkOrderPage />
    )
  }
  const bottomInfo = (
    <section className="narrow">
      <HowItWorks />
      <WorkOrderProcessCard />
      <div className="contact-us-and-help-resources-container">
        <HelpAndResourcesCard className="landing-page__help-and-resources-card" />
        <ContactUsCard className="landing-page__contact-us-card" />
      </div>

    </section>
  );

  const [staticModalOpen, setStaticModalOpen] = useState(false)
  // const handleStaticModal = () => {
  //   alert('ok')
  //   localStorage.setItem('cmcStaticModalText', `You currently have no active service orders for this account. If you would like to submit a new request for service, please call 800.338.0178. If you are trying to access your DTE Energy account, please <a href="${getDomain()}wps/wcm/connect/dte-web/login/">sign in.</a>`)
  //   setStaticModalOpen(true)
  // }
  const landingPageContent = (
    <main className="landing-page">
    
    <div className="hero" aria-hidden="true" />
    <div className="log-in-container">
      {userSession && props.history.push("/home")}
      <LogInCard 
        setGuestSearchWON={setGuestSearchWON} 
        setStaticModalOpen={setStaticModalOpen}/>
      <WelcomeCard />
      {staticModalOpen && <StaticModal 
            open={true}
            className={`open`} //isErrorModalOpen ? `open` : ``}
            text={cacheHandler.get('staticModalText') || "Sorry, an error occurred"}
            heading={cacheHandler.get('staticModalTitle') || ""}
            buttonText={cacheHandler.get('staticModalButtonText') || ""}
      />}
    </div>

      { bottomInfo }
    </main>
  );

  return guestSearchWON ? guestSearch() : landingPageContent;
};

export default withRouter(LandingPage);
